
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "startseite",
        "locale": "de",
        "pageId": 1,
        "sections": [
          {
            "sectionId": "orbg8s",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              {
                "fieldType": "campaign-grid",
                "fieldId": "x1p9hx",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Error
        "pageName": "error",
        isPublic: true,
        pageId: 12082,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Oops...",
                                    classes: 'heading text-left',
                                    styles: {
                                      "font-size": "56px",
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: "Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut.",
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/error.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur ARIEL website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.ariel.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // DOI
        pageName: "doi",
        pageId: 125,
        isPublic: true,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              justifyContent: "center",
              alignItems: "center",
              margin: 0
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // DOI CHECK
                fieldType: "doi",
                fieldId: "doiasdasda",
                properties: {
                  endpoint: '/checkDoi'
                }
              },
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "80px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 6,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        fields: [
                          { // Heading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              text: "Danke für Ihre Anmeldung",
                              classes: 'heading-black text-left'
                            }
                          },
                          { // Subheading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              text: "Sie haben sich erfolgreich für unseren Newsletter angemeldet. Ab sofort erfahren Sie als Eine/Einer der Ersten, sobald des kostenlose Produkttests, Gewinnspiele und inspirierende Artikel gibt.",
                              classes: 'subHeading text-left'
                            }
                          }
                        ]
                      }
                    },
                    { // Thank you image mobile
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/thanks.png",
                              "alternateText": "SMS",
                              "classes": "sms-image",
                              "styles": {
                                "max-width": "250px"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // Section
            grid: {
              columns: 12,
              alignItems: "center",
              justifyContent: "center"
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "60px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 8,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        classes: "container",
                        fields: [

                        ]
                      }
                    },
                    { // Key visual
                      fieldType: "key-visual",
                      fieldId: "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          padding: '0 0 0 0',
                          maxWidth: '75vw'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/banner-desktop.jpg",
                        tabletImageUrl: "assets/images/banner/banner-desktop.jpg",
                        mobileImageUrl: "assets/images/banner/banner-mobile.jpg"
                      }
                    },
                    { // Container
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 12,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "60px"
                        },
                        classes: "container",
                        fields: [
                          { // Buttons
                            fieldType: "empty-bucket",
                            fieldId: "fu0923r",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              classes: "btn-bucket",
                              fields: [
                                { // Continue Button
                                  fieldType: "button-component",
                                  fieldId: "but100",
                                  properties: {
                                    grid: {
                                      columns: 8
                                    },
                                    text: "Zurück zur ARIEL website",
                                    classes: "btn-primary",
                                    action: {
                                      fn: "goToLink",
                                      params: "https://www.ariel.de/de-de"
                                    },
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms and Conditions
        "pageName": "terms-and-conditions",
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
            },
            "fields": [
              {
                "fieldType": "terms-component",
                "fieldId": "a6prst"
              }
            ]
          }
        ]
      },
    ],
    "header": {
      "fieldType": "navbar-new",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        inCampaign: false,
        "logo": {
          imageSource: "assets/images/png/logo.png",
          url: '/',
          alt: 'Ariel logo'
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "meta": [
          { "name": "title", "content": "Ariel Gratis Testen" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `Ariel, Gratis, Testen, Ariel Gratis Testen, Ariel Gratis Testen`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
    },
    errors: {
      de: {
        default: "Hoppla, da ist was schief gelaufen!",
        noEmail: "Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.",
        emailUsed: "Diese E-Mail-Adresse wird bereits verwendet",
        incorrect: "Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!",
        invalidCode: "Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.",
        manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode: 'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network: 'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        '15minsOver': 'Die Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
        notActive: 'Ungültiger Teilnahmezeitraum. Bitte lesen Sie unsere Teilnahmebedingungen.',
        badRequest: 'Ungültige Anfrage: campaignKey ist erforderlich.',
        alreadyActive: 'Pro IP-Adresse ist nur eine Teilnahme innerhalb von 15 Minuten erlaubt. Bitte probieren Sie es in ein paar Minuten erneut.',
        dailyParticipationLimit: 'Das tägliche Limit wurde leider schon erreicht, aber es ist möglich, dass Plätze wieder frei werden, falls einige Kaufbelege abgelehnt werden. Bleiben Sie dran - vielleicht klappt es ja doch noch! Ansonsten können Sie es gerne morgen erneut versuchen. Der Zähler wird um 8 Uhr zurückgesetzt.'
      }
    },
    "url": "http://justsnap.everydayme.pl/",
    "name": "test-campaign"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: "ariel-cb-dach",
  campaignKey: "baa85b94-ca4c-4383-88f2-b53c93d91597",
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignEndDate: '9999-01-01',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "",
  errorActivate: false,
  scannedProducts: [],
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  remainingCount: 0,
  jwtToken: '',
  guestSelected: null,
  allowedPages: ['campaign-is-over', 'terms', 'faq', 'receipt-reupload', 'product-reupload', 'iban-reupload', 'error', 'expired']
}
