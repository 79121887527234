import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash'
import { BootstrapVue } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import '@/mixins';
import '@/vee-validate'
import VueMeta from 'vue-meta'
import i18n from './i18n'
import vSelect from 'vue-select'
import auth from './auth';



Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import './assets/styles/resp-table.css';


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.prototype._ = lodash

import '@/helpers/common-components.js';
Vue.config.productionTip = false
const EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $eventHub: {
    get: function () {
      return EventBus
    }
  }
})

Vue.use(auth);


new Vue({
  store,
  i18n,
  router,
  created () {
    localize('de', require(`vee-validate/dist/locale/de.json`))
  },
  render: h => {
    return h(App)
  },
}).$mount('#app > div')
