<template>
  <div
    class="keyVisualWrapper"
    :style="styles"
    :class="addClass"
  >
    <div :class="{'container': !noContainer}">
      <img
        class="desktop_img img-fluid w-100"
        :src="require(`@/${desktopImageUrl}`)"
        :alt="alt"
      />
      <img
        class="tablet_img img-fluid w-100"
        :src="require(`@/${tabletImageUrl}`)"
        :alt="alt"
      />
      <img
        class="mobile_img img-fluid w-100"
        :src="require(`@/${mobileImageUrl}`)"
        :alt="alt"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyVisual",
  props: {
    desktopImageUrl: {
      type: String,
      default: "assets/images/banner/banner.png"
    },
    mobileImageUrl: {
      type: String,
      default: "assets/images/banner/banner.png"
    },
    tabletImageUrl: {
      type: String,
      default: "assets/images/banner/banner.png"
    },
    noContainer: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {}
    },
    addClass: {
      type: String,
      required: false,
      default: ''
    },
    alt: {
      type: String,
      required: false,
      default: "banner"
    }
  }
};
</script>

<style scoped lang="scss">
.keyVisualWrapper {
  width: 100%;
  padding: 0;
}

.desktop_img {
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet_img {
  @media (max-width: 435px), (min-width: 769px) {
    display: none;
  }
}
.fullWidth {
  @media(max-width: 450px){
    max-width: 100vw !important;
    padding: 0px !important;
  }
}
.mobile_img {
  @media (min-width: 436px) {
    display: none;
  }
}

</style>
