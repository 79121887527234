import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import ariel from '../store/ariel';
import { auth } from "../auth";


Vue.use(VueRouter)
function loadPage (pagePath) {
  return () => {
    return import(`@/${pagePath}`)
  };
}


const goTo = (to, from, next) => {
  console.log("ROUTE", this, from, next)
}

function requireAuth(to, from, next) {
  const allowedPages = [
    'campaign-is-over', 'terms', 'faq', 'receipt-reupload', 'product-reupload', 'iban-reupload', 'error', 'expired'
  ];

  if (allowedPages.some(page => to.path.includes(page))) {
    return next();
  }

  const checkAuth = () => {
    if (!auth.isAuthenticated) {
      next({ path: '/' });
      store.replaceState(ariel)
    } else {
      next();
    }
  };

  if (!auth.loading) {
    checkAuth();
  } else {
    const unwatch = auth.$watch('loading', (newVal) => {
      if (!newVal) {
        unwatch();
        checkAuth();
      }
    });
  }
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: loadPage("views/LoginCallback.vue"),
  },
  {
    path: "/:campaign/bank",
    name: "bank",
    component: loadPage("views/Home.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue'),

  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes
})

let campaigns = {
  'unstoppables': 'unstoppables',
  'gratis': 'gratis',
}

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  if (to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  } else {
    const path = to.fullPath
    Object.keys(campaigns).forEach(x => {
      const pathSegments = path.split('/');
      const segment = pathSegments[1].split('?')[0];
      if (segment === x) {
        const campaign = require(`../store/campaigns/${campaigns[x]}`).default;
        campaign.pageProperties.testing = store.state.pageProperties.testing;
        store.replaceState(campaign);
      }
    });
    /**
       * Check the campaign ending date, and if its true,
       * redirect to 'campaign-is-over' page
    */
    const endDate = new Date(store.state.campaignEndDate).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const dayInSeconds = 86400; // If we need to include the ending day
    let camEnded = (new Date(endDate).getTime() / 1000 + dayInSeconds) < (new Date(currentTime).getTime() / 1000)
    let allowedPages = ['campaign-is-over', 'terms', 'faq', 'receipt-reupload', 'product-reupload', 'receipt-re-upload', 'product-re-upload', 'iban-reupload', 'error', 'expired']
    if (camEnded) {
      if (!allowedPages.find(x => path.includes(x))) {
        if (path.includes("/")) return window.location.href = `campaign-is-over`
        else return window.location.href = `${path}/campaign-is-over`
      }
    }
    next();
  }
})

export default router
