
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "gratis",
        isPublic: true,
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "classes": "home-page",
            "fields": [
              // { // Refresh Token
              //   "fieldType": "refresh-token",
              //   "fieldId": "ij3433"
              // },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "marginBottom": "40px"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "assets/images/banner/gratis/banner-desktop.jpg",
                  "tabletImageUrl": "assets/images/banner/gratis/banner-desktop.jpg",
                  "mobileImageUrl": "assets/images/banner/gratis/banner-mobile.jpg"
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "fu0923r",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "40px",
                    "width": "100%"
                  },
                  "fields": [
                    {
                      "fieldType": "campaign-disclaimer",
                      "fieldId": "disclaimer",
                      "properties": {
                        "grid": {
                          "columns": "10"
                        },
                        "dates": "01.02.2025 - 15.06.2025",
                        "text1": "Einsendeschluss für das Onlineformular ist der 30.06.2025",
                        "text2": "Ein Teilnehmer kann mit maximal einem Aktionsprodukt teilnehmen.",
                      }
                    },
                  ]
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "40px",
                          marginTop: "40px"
                        },
                        text: "Schritte zur Teilnahme an der Aktion",
                        classes: 'title text-center'
                      }
                    },
                    { // Steps Card
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "marginTop": "60px",
                          "marginBottom": "60px",
                          "display": "flex",
                          "flex-direction": "row",
                          "justify-content": "center",
                          "columnGap": "40px"
                        },
                        "class": "home-steps",
                        "fields": [
                          {
                            "fieldType": "steps-horizontal",
                            "fieldId": "steps",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              colorSchema: "#FCFCFC",
                              steps: [
                                {
                                  stepText: "Produkt kaufen",
                                  icon: {
                                    src: "assets/images/steps/step-1.png",
                                    alt: "step1"
                                  }
                                },
                                {
                                  stepText: "Kaufbeleg hochladen",
                                  icon: {
                                    src: "assets/images/steps/step-2.png",
                                    alt: "step2"
                                  }
                                },
                                {
                                  stepText: "Geld zurück erhalten",
                                  icon: {
                                    src: "assets/images/steps/step-4.png",
                                    alt: "step3"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Button
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "marginTop": "40px",
                          'justify-content': 'center'
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "x1p9hx",
                            "properties": {
                              "grid": {
                                "columns": 5
                              },
                              "text": "Rückerstattung starten",
                              "classes": "btn-primary text-uppercase",
                              "disabled": false,
                              "startCampaignButton": true,
                              "action": [
                                {
                                  "fn": "goToPage",
                                  "params": "gratis/barcode"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Barcode
        "pageName": "gratis/barcode",
        pageId: 10011,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": false
                          },
                          {
                            "label": "Geld zurück erhalten",
                            "active": false
                          }
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Scannen Sie das Produkt",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: `Bitte geben Sie die Barcodenummer Ihres Produktes ein oder scannen Sie den Barcode. Bitte achten Sie darauf, dass die Barcode-Informationen des Aktionsproduktes gut erkennbar sind.`,
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Barcode scanner
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Barcode Scanner
                            "fieldType": "barcode-scanner",
                            "fieldId": "barcodeScanner",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "gratis/receipt-upload"
                              },
                              "endpoint": "/submitProducts",
                              "texts": {
                                receiptUpload: 'Upload',
                                receiptUploadDescription: 'Upload barcode image',
                                receiptUploadSubDescription: 'Drag & Drop',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.',
                                barcodeScan: 'SCAN',
                                stop: 'STOP',
                                barcodeScanDescription: 'Click here and scan the barcode.',
                                barcodeScanSubDescription: '',
                                barcodeAdd: 'ADD',
                                barcodeAddDescription: 'Enter barcode number',
                                notParticipating: 'The barcode does not appear to belong to a P&G product. Do you think this is an error?',
                                notParticipatingVideo: 'Unfortunately, the product does not appear to be participating in the promotion. Please try to enter the barcode in the field above.',
                                timedOut: 'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.',
                                cameraNotFound: 'Please turn on your camera',
                                invalidBarCode: 'The barcode number is invalid. Try it again.'
                              },
                              "cameraTimeOut": 20000,
                              "maxProductCount": 1
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["barcodeScanner"],
                              "action": {
                                "fn": "sendBarcodeProducts",
                                "params": ""
                              }
                            }
                          },
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
         "pageName": "gratis/receipt-upload",
         pageId: 10099,
         "sections": [
           { // Steps and Header
             "grid": {
               "columns": 12,
               "alignItems": "center",
               "justifyContent": "center",
               "marginTop": "50px"
             },
             "sectionId": "fu30zq",
             "fields": [
               { // Container
                 "fieldId": "fu0923r",
                 "fieldType": "empty-bucket",
                 "properties": {
                   "grid": {
                     "columns": 12,
                     "alignItems": "center",
                     "justifyContent": "center",
                     "marginBottom": "60px"
                   },
                   "classes": "container",
                   fields: [
                     { // Progress Bar
                        "fieldType": "progress-bar",
                        "fieldId": "progressBar",
                        "properties": {
                          grid: {
                            columns: 8,
                            marginBottom: "50px"
                          },
                          "steps": [
                            {
                              "label": "Produkt kaufen",
                              "done": true,
                              "active": true
                            },
                            {
                              "label": "Kaufbeleg hochladen",
                              "done": true
                            },
                            {
                              "label": "Geld zurück erhalten",
                              "active": false
                            }
                          ]
                        }
                     },
                     { // Heading
                       "fieldType": "paragraph",
                       "fieldId": "par1001",
                       properties: {
                         grid: {
                           columns: 12,
                           marginBottom: "20px"
                         },
                         text: "Kaufbeleg + Produktrückseite mit Barcode hochladen",
                         classes: 'heading text-left'
                       }
                     },
                     { // Subheading
                       "fieldType": "paragraph",
                       "fieldId": "par1001",
                       properties: {
                         grid: {
                           columns: 12,
                           marginBottom: "50px"
                         },
                         text: `Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes, gemeinsam mit der Rückseite des Produktes hoch. Bitte beachten Sie, dass der Barcode und das Aktionsprodukt gut ersichtlich sein müssen.`,
                         classes: 'subHeading text-left'
                       }
                     },
                     { // Receipt uploader
                       "fieldType": "empty-bucket",
                       "fieldId": "h453eadaf",
                       "properties": {
                         "grid": {
                           "columns": 12,
                           "alignItems": "center",
                           "justifyContent": "space-around",
                           "marginBottom": "60px"
                         },
                         "classes": "receipt-bucket",
                         "fields": [
                           { // Recipt upload
                             "fieldType": "receipt-processor",
                             "fieldId": "klvakj",
                             "properties": {
                               "grid": {
                                 "columns": "4",
                                 "alignItems": "center",
                                 "justifyContent": "center"
                               },
                               "successAction": {
                                 "fn": "goToPage",
                                 "params": "gratis/auth-select"
                               },
                               postBillId: 'pg-dach-2024-2',
                               texts: {
                                 choose: 'Upload',
                                 text1: 'Drop your photo here or select file.',
                                 text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                 wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                 fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                               },
                               editTexts: {
                                 crop: "Crop",
                                 erase: "Erase",
                                 drag: "Drag",
                                 zoomIn: "Zoom in",
                                 zoomOut: "Zoom out"
                               },
                               colorSchema: "#DF1B25",
                               "phrases": [
                                 "Please erase your personal data with the eraser tool.",
                                 "Please frame the receipt / invoice in the gray rectangle."
                               ],
                               "cornerDetectionPhrases": [
                                 "Please erase your personal data with the eraser tool.",
                                 "Please frame the receipt / invoice in the gray rectangle."
                               ]
                             }
                           },
                           { // Receipt Info
                             "fieldType": "empty-bucket",
                             "fieldId": "vdf932fd",
                             "properties": {
                               "grid": {
                                 "columns": "8"
                               },
                               "fields": [
                                 {
                                   "fieldType": "receipt-information",
                                   "fieldId": "5mq1p6",
                                   "properties": {
                                     "grid": {
                                       "alignItems": "center",
                                       "justifyContent": "center",
                                       "columns": 12
                                     },
                                     shouldDisplayFull: true,
                                     "texts": {
                                       "descText": `The following information must be visible:`,
                                       "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                       "correct": "Valid",
                                       "notReadabe": "Hard to read",
                                       "folded": "Folded",
                                       "cropped": "Cut off"
                                     },
                                     "hideExamples": false,
                                     "steps": [
                                       { // Retailer
                                         "text": "Retailer / Shop",
                                         "label": "A"
                                       },
                                       {
                                         "text": "Campaign product and its price",
                                         "label": "B"
                                       },
                                       {
                                         "text": "Total sum of the receipt / invoice and applied VAT",
                                         "label": "C"
                                       },
                                       {
                                         "text": "Date and time of purchase",
                                         "label": "D"
                                       },
                                       {
                                         "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                         "label": "E"
                                       },
                                       {
                                        "text": "Barcode-Nummer",
                                        "label": "F"
                                      }
                                     ]
                                   }
                                 }
                               ]
                             }
                           }
                         ]
                       }
                     },
                     { // Buttons
                       "fieldId": "fu0923r",
                       "fieldType": "empty-bucket",
                       "properties": {
                         "grid": {
                           "columns": "12",
                           "marginBottom": "30px"
                         },
                         classes: "btn-bucket",
                         fields: [
                           { // Continue Button
                             "fieldType": "button-component",
                             "fieldId": "but100",
                             properties: {
                               grid: {
                                 columns: 3
                               },
                               text: "Continue",
                               classes: "btn-primary",
                               "requires": ["klvakj"],
                               "action": {
                                 "fn": "sendReceipt",
                                 "params": ""
                               }
                             }
                           }
                         ]
                       }
                     }
                   ]
                 }
               }
             ]
           }
         ]
      },
      { // Login Page
        "pageName": "gratis/login",
        pageId: 1202,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Geld zurück erhalten",
                            "done": true
                          }
                        ]
                      }
                   },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Mit <img src='/assets/images/forme.png' width='85px' alt='ForMe' /> Account einloggen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Mit einem <img src='/assets/images/forme.png' width='50px' alt='ForMe' /> Konto können Sie schneller und einfacher an zukünftigen Geld-Zurück-Aktionen teilnehmen. Außerdem finden Sie bei <img src='/assets/images/forme.png' width='50px' alt='ForMe' /> weitere spannende Gratis-Testen-Aktionen und Gewinnspiele von zahlreichen Markenprodukten.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Login
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        classes: "login-page-bucket",
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "loginForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/login",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/bank"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            { // Email
                                              "fieldId": "n6b54v",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "id": "email",
                                                "name": "email",
                                                "label": "E-mail",
                                                "placeholder": "example@example.com",
                                                "text": "",
                                                "labelOnTop": true,
                                                "validation": "required|email",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            { // Password
                                              "fieldId": "6h54bdd",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "id": "password",
                                                "name": "password",
                                                "label": "Passwort",
                                                "inputType": "password",
                                                "placeholder": "Passwort",
                                                "text": "",
                                                "labelOnTop": true,
                                                "validation": "required",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                styles: {
                                                  "color": "#4E4B66"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": "Ich habe die <a href='https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/gratis/terms.pdf' target='_blank'>Teilnahmebedingungen</a> dieser Aktion gelesen und akzeptiere diese.*"
                                              }
                                            },
                                            { // Login Button
                                              "fieldType": "empty-bucket",
                                              "fieldId": "vb892nwq",
                                              "properties": {
                                                grid: {
                                                  columns: 12,
                                                  'alignItems': 'center',
                                                  "justifyContent": "center"
                                                },
                                                fields: [
                                                  { // Next Button
                                                    "fieldType": "button-component",
                                                    "fieldId": "9o8k7ujyth",
                                                    "properties": {
                                                      "grid": {
                                                        "columns": 6,
                                                        "alignItems": "center",
                                                        "justify-content": "center",
                                                        "marginTop":"20px"
                                                      },
                                                      "classes": "btn-primary",
                                                      "text": "Weiter",
                                                      "disabled": false,
                                                      "action": {
                                                        "fn": "sendForm",
                                                        "params": "loginForm"
                                                      }
                                                    }
                                                  },
                                                  { // Forgot Password
                                                    "fieldType": "anchor",
                                                    "fieldId": "fckzg7",
                                                    "properties": {
                                                      "text": "Passwort vergessen?",
                                                      "url": "https://www.for-me-online.de/passwort-vergessen",
                                                      "target": "_blank",
                                                      "grid": {
                                                        "alignItems": "center",
                                                        "justifyContent": "center",
                                                        "columns": "12"
                                                      },
                                                      "cssClass": "forgotPassword",
                                                      "styles": {
                                                        "textAlign": "center",
                                                        "marginTop": "10px",
                                                        "marginBottom": "20px",
                                                        "textDecoration": "underline",
                                                        "color": "#0057B8"
                                                      }
                                                    }
                                                  },
                                                ]
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "h453eadaf",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "padding": "20px",
                                      flexDirection: "column",
                                      rowGap: "20px"
                                    },
                                    "classes": "form-wrapper",
                                    "fields": [
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          grid: {
                                            columns: 12
                                          },
                                          text: `Kein ForMe Account?
                                          <br>
                                          Kostenlos registrieren!`,
                                          classes: 'text text-center',
                                          styles: {
                                            "font-weight": 700
                                          }
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "REGISTRIEREN",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "goToPage",
                                            "params": "gratis/register"
                                          }
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "WEITER ALS GAST",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "goToPage",
                                            "params": "gratis/guest-register"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                              ]
                            }
                          },
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Register
        "pageName": "gratis/register",
        pageId: 1203,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Geld zurück erhalten",
                            "done": true
                          }
                        ]
                      }
                   },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bei <img src='/assets/images/forme.png' width='85px' alt='ForMe' /> registrieren",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Wir benötigen folgende Informationen, um Ihre Registrierung bei <img src='/assets/images/forme.png' width='50px' alt='ForMe' /> abschließen zu können.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "regForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/register",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/bank"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "yh54weg",
                                              "properties": {
                                                "text": "Über Sie",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": "12"
                                                },
                                                "classes": "form-title"
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "b4baqs",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "firstName",
                                                      "id": "firstName",
                                                      "label": "Vorname",
                                                      "placeholder": "Vorname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "gofije",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "lastName",
                                                      "id": "lastName",
                                                      "label": "Nachname",
                                                      "placeholder": "Nachname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "x3d8oz",
                                                    "fieldType": "select-input",
                                                    "properties": {
                                                      "name": "gender",
                                                      "id": "gender",
                                                      "label": "Anrede",
                                                      "placeholder": "Anrede",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      selectedValue: "other",
                                                      "options": [
                                                        {
                                                          "text": "Herr",
                                                          "value": "male"
                                                        },
                                                        {
                                                          "text": "Frau",
                                                          "value": "female"
                                                        },
                                                        {
                                                          "text": "Divers",
                                                          "value": "miscellaneous"
                                                        },
                                                        {
                                                          "text": "Keine Angabe",
                                                          "value": "other"
                                                        }
                                                      ],
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "godate",
                                                    "fieldType": "date-input",
                                                    "properties": {
                                                      "name": "birthdate",
                                                      "id": "birthdate",
                                                      "label": "Geburtsdatum",
                                                      "placeholder": "dd/mm/yyyy",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      "grid": {
                                                        "columns": "6"
                                                      },
                                                      "params": {
                                                        "disabledDates": {
                                                          "from": "18years",
                                                          "to": "01.01.1900"
                                                        }
                                                      }
                                                    }
                                                  },
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "h7kzf9",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "email",
                                                      "id": "email",
                                                      "label": "E-mail",
                                                      "placeholder": "example@example.com",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|email",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  { // Password
                                                    "fieldId": "6h54bdd",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "id": "password",
                                                      "name": "password",
                                                      "label": "Passwort",
                                                      "inputType": "password",
                                                      "placeholder": "Passwort",
                                                      "text": "",
                                                      "labelOnTop": true,
                                                      "validation": "required|password",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "x3d8oz",
                                                    "fieldType": "select-input",
                                                    "properties": {
                                                      "name": "land",
                                                      "id": "land",
                                                      "label": "Land",
                                                      "placeholder": "Land",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required",
                                                      "options": [
                                                        {
                                                          "text": "Deutschland",
                                                          "value": "Germany"
                                                        },
                                                        {
                                                          "text": "Schweiz",
                                                          "value": "Switzerland"
                                                        },
                                                        {
                                                          "text": "Österreich",
                                                          "value": "Austria"
                                                        }
                                                      ],
                                                      "selectedValue": "",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldType": "paragraph",
                                                    "fieldId": "yh54weg",
                                                    "properties": {
                                                      "text": "Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und eine Zahl (0-9) enthalten.",
                                                      "grid": {
                                                        "columns": "6",
                                                        "marginBottom": "30px"
                                                      },
                                                      "classes": "pass-text"
                                                    }
                                                  },
                                                ]
                                              }
                                            },
                                          ]
                                        }
                                      },
                                      { // Checkboxes
                                        "fieldId": "8k2f023f",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": "12"
                                          },
                                          "fields": [
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich habe die <a href="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/gratis/terms.pdf" target="_blank"><b><u>Teilnahmebedingungen</u></b></a> dieser Aktion gelesen und akzeptiere diese.*`
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j7",
                                              "properties": {
                                                "name": "terms",
                                                "id": "terms",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich bestätige, dass ich über 18 Jahre alt bin, und die <a href="https://termsandconditions.pg.com/de-de/" target="_blank"><u><b>Geschäftsbedingungen</b></u></a>, <a href="http://www.pg.com/privacy/german/privacy_statement.shtml" target="_blank"><u><b>Datenschutzrichtlinien</b></u></a> &amp; <a href="https://justsnap.de/Datenschutz" target="_blank"><u><b>Justsnap Datenschutzrichtlinien</b></u></a> akzeptiere.
                                                <br>
                                                Sie können Ihre <b>Datenschutzrechte</b> ausüben oder ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank'>Meine Anfrage zum Datenschutz</a> ändern.*`
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "psyn1c",
                                              "properties": {
                                                "name": "receiveEmails",
                                                "id": "receiveEmails",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": true
                                                  }
                                                },
                                                colorSchema: "#4E4B66",
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                "label": `Bleiben Sie mit uns in Kontakt!
                                                <br><br>
                                                Ich bin damit einverstanden, personalisierte Mitteilungen über Angebote, Neuigkeiten und andere Werbeinitiativen von for me und anderen <a href='https://de.pg.com/marken-und-produkte/' target='_blank'><u><b>P&G-Marken</b></u></a> per E-Mail und über Online-Kanäle zu erhalten. Ich kann die Mitteilungen jederzeit wieder <a href='https://preferencecenter.pg.com/de-de/datarequests/' target='_blank'><u><b>abbestellen</b></u></a>.
                                                <br><br>
                                                Procter & Gamble verarbeitet als Datenverantwortlicher Ihre personenbezogenen Daten, um Ihnen zu ermöglichen, sich auf dieser Website zu registrieren, mit deren Diensten zu interagieren und, sofern Sie zustimmen, Ihnen relevante werbliche Mitteilungen zukommen zu lassen, darunter auch personalisierte Werbebotschaften in den Online-Medien.
                                                <br><br>
                                                <u class='modalOpener' data-text='
                                                  <div>
                                                    <b>*Mehr lesen</b><br>
                                                    Relevante Werbeinformationen und personalisierte Online-Werbebotschaften
                                                    <br><br>
                                                    Unser Ziel ist es, Ihren Kontakt mit uns und den Marken der P&G Familie interessant, angenehm und persönlich zu gestalten. Dabei versuchen wir, unsere werblichen Mitteilungen nach Ihren Vorlieben abzustimmen und vermeiden es, Ihnen Vorschläge zu schicken, die nicht Ihrem Geschmack entsprechen, oder Ihnen Werbebotschaften zukommen zu lassen, die Ihnen bereits mehrfach angezeigt wurden. Wie machen wir das?
                                                    <br><br>
                                                    <ul>
                                                      <li>Wir kombinieren Daten, um unsere Mitteilungen besser auf Ihre Interessen abzustimmen. Wir können auf der Grundlage unseres berechtigten Interesses sowohl Ihre Registrierungsdaten, sowie auch die Daten, die Sie uns bei der Interaktion mit unseren Diensten offenlegen (wie Markenpräferenzen, ausgefüllte Coupons usw.), mit weiteren Attributen, Interessen und demografischen Daten anreichern, die wir aus geschäftlich verfügbaren Quellen oder von anderen Drittparteien erhalten. Ebenso können wir, wenn Sie unsere zielgerichteten Werbe-Cookies oder ähnliche Technologien auf den Websites oder in den Apps von P&G, oder in einigen Fällen auch auf Webseiten oder in Apps von Dritten, akzeptiert haben, die durch unsere zielgerichteten Werbe-Cookies erhobenen Daten auch mit weiteren Informationen ergänzen, die Sie uns bei der Erstellung eines Kontos oder bei der Interaktion mit unseren Diensten zur Verfügung stellen. Wir verarbeiten diese Daten dabei auch mit Hilfe automatisierter Verfahren, um unsere Inhalte und E-Mail-Angebote online (z. B. auf Plattformen Dritter, darunter auch Social Media-Plattformen) wie auch offline (z. B. per Briefpost) zu personalisieren, sofern Sie dem Erhalt geschäftlicher Mitteilungen von uns zugestimmt haben.</li>
                                                      <br>
                                                      <li>    Lookalike-Targeting. Auf der Grundlage unseres berechtigten Interesses analysieren wir Ihre Daten auch, um neue Interessenten zu identifizieren, die ein ähnliches Profil wie Sie haben und sich für eine Kontaktaufnahme mit uns interessieren könnten. Wir und die mit P&G verbundenen Unternehmen können hierfür z. B. Ihre verschlüsselte E-Mail-Adresse in Facebook hochladen und Facebook darum bitten, unsere Werbeanzeigen auf der Grundlage von Daten, die über Sie und andere Personen vorliegen, für weitere Personen anzuzeigen, die ähnliche Interessen haben wie Sie, sogar in anderenLändern.</li>
                                                      <br>
                                                      <li>Verwendung Ihrer personenbezogenen Daten, um Sie in den Online-Medien zu adressieren. Wenn Sie eingewilligt haben, werbliche Mitteilungen von uns zu erhalten, werden wir Ihre verschlüsselten personenbezogenen Daten (d. h. Ihre E-Mail-Adresse, Telefonnummer oder Ihre Mobile Advertising-ID) verwenden, um Ihnen Werbebotschaften in den Online-Kanälen (z. B. auf Ihrem Social-Media-Konto) zukommen zu lassen. Wir tun dies mit Hilfe eines Verfahrens, das Ihre Privatsphäre respektiert, indem wir verschlüsselte Daten und sorgfältig ausgewählte Partner verwenden. Insbesondere geben wir Ihre personenbezogenen Daten nur an Dritte weiter, die uns eigens darauf zugeschnittene Werbedienste zur Verfügung stellen, darunter auch die Social-Media-Plattformen. Wir verwenden solche Dienste, um die von uns gespeicherten Hash-Daten mit den personenbezogenen Daten in deren Datenbank abzugleichen, um individuelle Zielgruppen zu erstellen und Werbebotschaften auf Ihre Interessen im Internet zuzuschneiden, darunter auch der Social Media-Plattformen, soweit dies nach geltendem Recht zulässig ist.</li>
                                                    </ul>
                                                    Das hilft uns, Ihnen relevantere Online-Werbebotschaften (Angebote, Informationen über Produkte und Innovationen) zukommen zu lassen, und Ihnen weniger Anzeigenwerbung zu zeigen, da wir erfahren, ob Ihnen eine bestimmte Werbebotschaft bereits zugesendet wurde, und ob wir Sie bei weiteren Werbebotschaften zu demselben Thema ausschließen können. Die meisten unserer Verbraucher wissen es zu schätzen, dass wir ihnen keine Werbeanzeigen für Produkte anzeigen lassen, die sie nicht interessieren oder die wir ihnen zuvor schon oft angezeigt haben, aber wenn Ihnen das nicht wichtig ist, ist das auch kein Problem, da Sie Ihre Präferenzen jederzeit ändern können. Sie können jederzeit Ihre Meinung ändern und die Zustimmung zur Verwendung Ihrer Daten für personalisierte Werbebotschaften und Anzeigenwerbung, darunter auch die Erstellung von benutzerdefinierten Zielgruppen („Custom Audiences“) und Lookalike-Zielgruppen, <a
                                                      href="https://privacypolicy.pg.com/de-DE/"
                                                      target="_blank"
                                                    ><u>hier</u></a> widerrufen. Lesen Sie unsere Datenschutzrichtlinie.
                                                  </div>'
                                                ><b>Mehr lesen*</b></u>.
                                                Weitere Informationen über die Verarbeitung Ihrer Daten und Ihre Datenschutzrechte finden Sie 
                                                <u class='modalOpener' data-text='
                                                  <div>
                                                    <b>**Wir legen Wert auf Ihre Privatsphäre</b><br>
                                                    <ul>
                                                      <li>
                                                        Unsere <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="https://privacypolicy.pg.com/de-DE/"
                                                        >Datenschutzrichtlinie</a> regelt die Erfassung und Verwendung Ihrer Daten.
                                                      </li>
                                                      <br>
                                                      <li>Das ist der Grund, warum wir Ihre Daten erheben, unsere Rechtsgrundlage für deren Verarbeitung und der Zeitraum, für den Ihre personenbezogenen Daten speichern werden.</li>
                                                      <br>
                                                      <li>Unsere E-Mails enthalten Pixel, die P&G helfen zu verstehen, ob Sie mit ihnen interagieren und welche Inhalte bisher für Sie interessant waren. Erfahren Sie mehr dazu <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>hier</u></a>. Sie können sich von unseren Programmen für E-Mail-Mitteilungen jederzeit <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>abmelden</u></a>.
                                                      </li>
                                                      <br>
                                                      <li>Ihre personenbezogenen Daten können in einem anderen Land verarbeitet werden, als dem Land, in dem sie erhoben wurden, darunter auch in den USA. Erfahren Sie mehr dazu <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>hier</u></a>.
                                                      </li>
                                                      <br>
                                                      <li>Sie können Ihre Betroffenen Rechte ausüben (also auf Ihre personenbezogenen Daten zugreifen, sie berichtigen, löschen, an einen anderen Anbieter übermitteln lassen, der Verarbeitung Ihrer Daten widersprechen oder diese einschränken oder Ihre Einwilligung widerrufen) oder Ihre Präferenzen und Abonnements ändern, indem Sie <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>hier</u></a> klicken.
                                                      </li>
                                                    </ul>
                                                  </div>'
                                                ><b>hier**</b></u>
                                                oder in unserer vollständigen <a href='https://privacypolicy.pg.com/de-DE/' target='_blank'><u><b>Datenschutzrichtlinie</b></u>
                                                </a>. Sie sind mindestens 18 Jahre alt, haben die Datenschutzrichtlinien zur Kenntnis genommen und stimmen unseren <a href='https://termsandconditions.pg.com/de-de/' target='_blank'><u><b>Geschäftsbedingungen</b></u></a> zu.`,
                                              }
                                            },
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "gratis/login"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "regForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "flexDirection": "column"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "marginBottom": "30px",
                                "padding": "20px",
                                flexDirection: "column",
                                rowGap: "20px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  "properties": {
                                    grid: {
                                      columns: 12
                                    },
                                    text: "Bereits Mitglied bei ForMe?",
                                    classes: 'text text-center',
                                    styles: {
                                      "font-weight": 700
                                    }
                                  }
                                },
                                {
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "ANMELDEN",
                                    classes: "btn-tertiary",
                                    "action": {
                                      "fn": "goToPage",
                                      "params": "gratis/login"
                                    }
                                  }
                                },
                                {
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "WEITER ALS GAST",
                                    classes: "btn-tertiary",
                                    "action": {
                                      "fn": "goToPage",
                                      "params": "gratis/guest-register"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Auth Select
        "pageName": "gratis/auth-select",
        isPublic: true,
        pageId: 1204,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Geld zurück erhalten",
                            "done": true
                          }
                        ]
                      }
                   },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "INFORMATIONSFELDER AUSFÜLLEN UND AN DER GRATIS TESTEN AKTION TEILNEHMEN",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte füllen Sie nachstehendes Formular aus, um an der Gratis Testen Aktion teilzunehmen. Falls Sie wünschen, können Sie sich weiter unten bei ForMe registrieren oder anmelden, dies hat keinerlei Einfluss auf Ihre Teilnahme an der Aktion.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Button Box
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "30px",
                                "padding": "20px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "h453eadaf",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                    },
                                    "fields": [
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          grid: {
                                            columns: 6
                                          },
                                          text: `Sie haben noch kein ForMe Konto? <span class='modalOpener' data-text='ForMe ist eine Online-Plattform von <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">Procter & Gamble</a>, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">neue Produkte zum Testen</a> deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um <a href="https://www.for-me-online.de/familie" target="_blank">Familie</a>, <a href="https://www.for-me-online.de/haus-und-garten" target="_blank">Haus & Garten</a>, <a href="https://www.for-me-online.de/gesundheit-und-wellness" target="_blank">Gesundheit & Wellness</a> sowie <a href="https://www.for-me-online.de/beauty" target="_blank">Beauty</a>. Damit du alle Infos zuerst erhältst, kannst du dich <a href="https://www.for-me-online.de/anmelden" target="_blank">hier</a> für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf <a href="https://www.instagram.com/formebotschafter/?hl=de" target="_blank">Instagram</a> oder <a href="https://de-de.facebook.com/formeonline/" target="_blank">Facebook</a> vorbei!' style='font-size: 12px; color: green; vertical-align: top; text-decoration: none;'>&#9432;</span>`,
                                          classes: 'text text-left',
                                          styles: {
                                            "font-weight": 700
                                          }
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "REGISTRIEREN",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "oktaRegister",
                                            "params": ""
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "h453eadaf",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                    },
                                    "fields": [
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          grid: {
                                            columns: 6
                                          },
                                          text: `Schon registriert bei ForMe? <span class='modalOpener' data-text='ForMe ist eine Online-Plattform von <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">Procter & Gamble</a>, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">neue Produkte zum Testen</a> deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um <a href="https://www.for-me-online.de/familie" target="_blank">Familie</a>, <a href="https://www.for-me-online.de/haus-und-garten" target="_blank">Haus & Garten</a>, <a href="https://www.for-me-online.de/gesundheit-und-wellness" target="_blank">Gesundheit & Wellness</a> sowie <a href="https://www.for-me-online.de/beauty" target="_blank">Beauty</a>. Damit du alle Infos zuerst erhältst, kannst du dich <a href="https://www.for-me-online.de/anmelden" target="_blank">hier</a> für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf <a href="https://www.instagram.com/formebotschafter/?hl=de" target="_blank">Instagram</a> oder <a href="https://de-de.facebook.com/formeonline/" target="_blank">Facebook</a> vorbei!' style='font-size: 12px; color: green; vertical-align: top; text-decoration: none;'>&#9432;</span>`,
                                          classes: 'text text-left',
                                          styles: {
                                            "font-weight": 700
                                          }
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "ANMELDEN",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "oktaLogin",
                                            "params": ""
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "h453eadaf",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                    },
                                    "fields": [
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          grid: {
                                            columns: 6
                                          },
                                          text: 'Ohne Anmeldung fortfahren?',
                                          classes: 'text text-left',
                                          styles: {
                                            "font-weight": 700
                                          }
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "WEITER ALS GAST",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "goToPage",
                                            "params": "gratis/guest-register"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                              ]
                            }
                          },
                        ]
                      }
                    },
                    // { // Back Button
                    //   "fieldType": "button-component",
                    //   "fieldId": "but100",
                    //   properties: {
                    //     grid: {
                    //       columns: 3
                    //     },
                    //     text: "Zurück",
                    //     classes: "btn-secondary",
                    //     "action": {
                    //       "fn": "goToPage",
                    //       "params": "receipt-upload"
                    //     }
                    //   }
                    // },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Guest Register
        "pageName": "gratis/guest-register",
        pageId: 1204,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Geld zurück erhalten",
                            "done": true
                          }
                        ]
                      }
                   },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "INFORMATIONSFELDER AUSFÜLLEN UND AN DER GRATIS TESTEN AKTION TEILNEHMEN",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte füllen Sie nachstehendes Formular aus, um an der Gratis Testen Aktion teilzunehmen. Falls Sie wünschen, können Sie sich weiter unten bei ForMe registrieren oder anmelden, dies hat keinerlei Einfluss auf Ihre Teilnahme an der Aktion.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "guestForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/guest-register",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/bank"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "yh54weg",
                                              "properties": {
                                                "text": "Über Sie",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": "12"
                                                },
                                                "classes": "form-title"
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "b4baqs",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "firstName",
                                                      "id": "firstName",
                                                      "label": "Vorname",
                                                      "placeholder": "Vorname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "gofije",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "lastName",
                                                      "id": "lastName",
                                                      "label": "Nachname",
                                                      "placeholder": "Nachname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "x3d8oz",
                                                    "fieldType": "select-input",
                                                    "properties": {
                                                      "name": "gender",
                                                      "id": "gender",
                                                      "label": "Anrede",
                                                      "placeholder": "Anrede",
                                                      "text": "",
                                                      selectedValue: "other",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      "options": [
                                                        {
                                                          "text": "Herr",
                                                          "value": "male"
                                                        },
                                                        {
                                                          "text": "Frau",
                                                          "value": "female"
                                                        },
                                                        {
                                                          "text": "Divers",
                                                          "value": "miscellaneous"
                                                        },
                                                        {
                                                          "text": "Keine Angabe",
                                                          "value": "other"
                                                        }
                                                      ],
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "h7kzf9",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "email",
                                                      "id": "email",
                                                      "label": "E-mail",
                                                      "placeholder": "example@example.com",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|email",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      { // Checkboxes
                                        "fieldId": "8k2f023f",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": "12"
                                          },
                                          "fields": [
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": "Ich habe die <a href='https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/gratis/terms.pdf' target='_blank'><u><b>Teilnahmebedingungen</b></u></a> dieser Aktion gelesen und akzeptiere diese.*"
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j7",
                                              "properties": {
                                                "name": "terms",
                                                "id": "terms",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich bestätige, dass ich über 18 Jahre alt bin, und die <a href="https://termsandconditions.pg.com/de-de/" target="_blank"><u><b>Geschäftsbedingungen</b></u></a>, <a href="http://www.pg.com/privacy/german/privacy_statement.shtml" target="_blank"><u><b>Datenschutzrichtlinien</b></u></a> &amp; <a href="https://justsnap.de/Datenschutz" target="_blank"><u><b>Justsnap Datenschutzrichtlinien</b></u></a> akzeptiere.
                                                <br>
                                                Sie können Ihre <b>Datenschutzrechte</b> ausüben oder ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank'>Meine Anfrage zum Datenschutz</a> ändern.*`
                                              }
                                            },
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "psyn1c",
                                              "properties": {
                                                "name": "receiveEmails",
                                                "id": "receiveEmails",
                                                styles: {
                                                  color: '#fff'
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                "text": `Der Datenverantwortliche, <a href="https://privacypolicy.pg.com/de-DE/" target="_blank"><u><b>Procter & Gamble</b></u></a>, verarbeitet Ihre personenbezogenen Daten, um Ihnen zu ermöglichen, sich auf dieser Webseite zu registrieren und mit deren Diensten zu interagieren. <br> <br>
                                                Weitere Informationen über die Verarbeitung Ihrer Daten und Ihre Datenschutzrechte finden Sie in unserer <a href="https://privacypolicy.pg.com/de-DE/" target="_blank"><u><b>Datenschutzrichtlinie</b></u></a>. <br> <br>
                                                Sie sind mindestens 18 Jahre alt und stimmen unseren <a href="https://termsandconditions.pg.com/de-de/"><u><b>Allgemeinen Geschäftsbedingungen</b></u></a> zu.`
                                              }
                                            },
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "gratis/auth-select"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "guestForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                    // {
                    //   "fieldType": "empty-bucket",
                    //   "fieldId": "h453eadaf",
                    //   "properties": {
                    //     "grid": {
                    //       "columns": 6,
                    //       "justifyContent": "center",
                    //       "alignItems": "center",
                    //       "flexDirection": "column",
                    //       "rowGap": "30px",
                    //       "padding": "20px"
                    //     },
                    //     "classes": "form-wrapper",
                    //     "fields": [
                    //       {
                    //         "fieldType": "empty-bucket",
                    //         "fieldId": "h453eadaf",
                    //         "properties": {
                    //           "grid": {
                    //             "columns": 12,
                    //             "justifyContent": "center",
                    //             "alignItems": "center",
                    //           },
                    //           "fields": [
                    //             {
                    //               "fieldType": "paragraph",
                    //               "fieldId": "par1001",
                    //               "properties": {
                    //                 grid: {
                    //                   columns: 6
                    //                 },
                    //                 text: `Sie haben noch kein ForMe Konto? <span class='modalOpener' data-text='ForMe ist eine Online-Plattform von <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">Procter & Gamble</a>, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">neue Produkte zum Testen</a> deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um <a href="https://www.for-me-online.de/familie" target="_blank">Familie</a>, <a href="https://www.for-me-online.de/haus-und-garten" target="_blank">Haus & Garten</a>, <a href="https://www.for-me-online.de/gesundheit-und-wellness" target="_blank">Gesundheit & Wellness</a> sowie <a href="https://www.for-me-online.de/beauty" target="_blank">Beauty</a>. Damit du alle Infos zuerst erhältst, kannst du dich <a href="https://www.for-me-online.de/anmelden" target="_blank">hier</a> für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf <a href="https://www.instagram.com/formebotschafter/?hl=de" target="_blank">Instagram</a> oder <a href="https://de-de.facebook.com/formeonline/" target="_blank">Facebook</a> vorbei!' style='font-size: 12px; color: green; vertical-align: top; text-decoration: none;'>&#9432;</span>`,
                    //                 classes: 'text text-left',
                    //                 styles: {
                    //                   "font-weight": 700
                    //                 }
                    //               }
                    //             },
                    //             {
                    //               "fieldType": "button-component",
                    //               "fieldId": "but100",
                    //               properties: {
                    //                 grid: {
                    //                   columns: 6
                    //                 },
                    //                 text: "REGISTRIEREN",
                    //                 classes: "btn-tertiary",
                    //                 "action": {
                    //                   "fn": "goToPage",
                    //                   "params": "register"
                    //                 }
                    //               }
                    //             }
                    //           ]
                    //         }
                    //       },
                    //       {
                    //         "fieldType": "empty-bucket",
                    //         "fieldId": "h453eadaf",
                    //         "properties": {
                    //           "grid": {
                    //             "columns": 12,
                    //             "justifyContent": "center",
                    //             "alignItems": "center",
                    //           },
                    //           "fields": [
                    //             {
                    //               "fieldType": "paragraph",
                    //               "fieldId": "par1001",
                    //               "properties": {
                    //                 grid: {
                    //                   columns: 6
                    //                 },
                    //                 text: `Schon registriert bei ForMe? <span class='modalOpener' data-text='ForMe ist eine Online-Plattform von <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">Procter & Gamble</a>, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie <a href="https://www.for-me-online.de/testen-und-gewinnen" target="_blank">neue Produkte zum Testen</a> deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um <a href="https://www.for-me-online.de/familie" target="_blank">Familie</a>, <a href="https://www.for-me-online.de/haus-und-garten" target="_blank">Haus & Garten</a>, <a href="https://www.for-me-online.de/gesundheit-und-wellness" target="_blank">Gesundheit & Wellness</a> sowie <a href="https://www.for-me-online.de/beauty" target="_blank">Beauty</a>. Damit du alle Infos zuerst erhältst, kannst du dich <a href="https://www.for-me-online.de/anmelden" target="_blank">hier</a> für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf <a href="https://www.instagram.com/formebotschafter/?hl=de" target="_blank">Instagram</a> oder <a href="https://de-de.facebook.com/formeonline/" target="_blank">Facebook</a> vorbei!' style='font-size: 12px; color: green; vertical-align: top; text-decoration: none;'>&#9432;</span>`,
                    //                 classes: 'text text-left',
                    //                 styles: {
                    //                   "font-weight": 700
                    //                 }
                    //               }
                    //             },
                    //             {
                    //               "fieldType": "button-component",
                    //               "fieldId": "but100",
                    //               properties: {
                    //                 grid: {
                    //                   columns: 6
                    //                 },
                    //                 text: "ANMELDEN",
                    //                 classes: "btn-tertiary",
                    //                 "action": {
                    //                   "fn": "goToPage",
                    //                   "params": "login"
                    //                 }
                    //               }
                    //             }
                    //           ]
                    //         }
                    //       },
                    //     ]
                    //   }
                    // },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN
        "pageName": "gratis/bank",
        pageId: 12031,
        isPublic: true,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Sign In Okta
                "fieldType": "sign-in-okta",
                "fieldId": "ij3433"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Geld zurück erhalten",
                            "done": true
                          }
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bankverbindung angeben",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Für Kaufbelege in Euro kann die Rückerstattung nur auf ein Euro-Konto erfolgen, respektive für CHF nur auf ein CHF-Konto. Bei Nichtbeachtung wird der Rückerstattungsprozess länger dauern und beiden Seiten zusätzliche Mühe abfordern. Wir bitten um Ihre Zusammenarbeit. Vielen Dank.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "ibanForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/submitIban",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/sms"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldId": "b4baqs",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "firstname",
                                                "id": "firstname",
                                                "label": "Vorname Kontoinhaber",
                                                "placeholder": "Vorname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofije",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "lastname",
                                                "id": "lastname",
                                                "label": "Nachname Kontoinhaber",
                                                "placeholder": "Nachname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "b4baqq",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "iban",
                                                "id": "iban",
                                                "label": "IBAN",
                                                "placeholder": "IBAN",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|iban",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofijx",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "bic",
                                                "id": "bic",
                                                "label": "BIC",
                                                "placeholder": "BIC",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|bic|bic-BOFS",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "text": '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                                          classes: 'subHeading text-left',
                                          styles: {
                                            "font-size": "14px",
                                            "font-weight": "400"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          // { // Back Button
                          //   "fieldType": "button-component",
                          //   "fieldId": "but100",
                          //   properties: {
                          //     grid: {
                          //       columns: 3
                          //     },
                          //     text: "Zurück",
                          //     classes: "btn-secondary",
                          //     "action": {
                          //       "fn": "goToPage",
                          //       "params": "gratis/guest-register"
                          //     }
                          //   }
                          // },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "ibanForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS
        "pageName": "gratis/sms",
        pageId: 1205,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/svg/lock.svg",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "130px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bitte bestätigen Sie Ihre Identität",
                        classes: 'heading text-center'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "50px"
                        },
                        text: "Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "sms-verification",
                                  "fieldId": "smsVer",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px"
                                    },
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/code"
                                    },
                                    "options": [
                                      {
                                        "name": "Deutschland",
                                        "code": "DE",
                                        "phoneCode": "+49"
                                      },
                                      {
                                        "name": "Schweiz",
                                        "code": "CH",
                                        "phoneCode": "+41"
                                      },
                                      {
                                        "name": "Österreich",
                                        "code": "AT",
                                        "phoneCode": "+43"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendSms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS Code
        "pageName": "gratis/code",
        pageId: 1206,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/svg/lock.svg",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "130px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bitte bestätigen Sie Ihre Identität",
                        classes: 'heading text-center'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "50px"
                        },
                        text: "Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "sms-receiving",
                                  "fieldId": "smsVer",
                                  "properties": {
                                    "grid": {
                                      "columns": 4,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px"
                                    },
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/thank-you"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "gratis/sms"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "verifySms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "gratis/thank-you",
        pageId: 1207,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "20px"
                              },
                              text: "Danke für die Teilnahme",
                              classes: 'heading text-left'
                            }
                          },
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              text: "Ihr Kassenbeleg und Ihre Daten wurden erfolgreich an uns übermittelt und werden jetzt überprüft.",
                              classes: 'subHeading text-left'
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column",
                                "paddingLeft": "0",
                                "marginLeft": "0"
                              },
                              "fields": [
                                { // Clipboard
                                  "fieldType": "clipboard",
                                  "fieldId": "ref123",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "marginBottom": "30px",
                                      "paddingLeft": "0",
                                      "marginLeft": "0"
                                    },
                                    "title": "Referenznummer:",
                                    colorSchema: "#3A855C",
                                    "variables": {
                                      "referenceNumber": "referenceNumber"
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px",
                                      paddingLeft: "0",
                                      marginLeft: "0"
                                    },
                                    text: "Sobald wir Ihre Daten überprüft haben, bekommen Sie entweder eine Bestätigungs-E-Mail oder eine E-Mail mit Rückfragen, falls Ihre Daten unvollständig waren oder Ihr Kaufbeleg nicht erfasst werden konnte. Dies kann bis zu 36 Stunden in Anspruch nehmen. Wir bitten um Ihre Geduld. Nach erfolgreicher Überprüfung des Kaufbelegs und aller weiteren Daten, überweisen wir den Erstattungsbetrag innerhalb von 6 Wochen auf das von Ihnen angegebene Konto. Sollten sich Unklarheiten ergeben, werden wir Sie per E-Mail nochmal separat kontaktieren.",
                                    classes: 'text text-left',
                                    styles: {
                                      "font-size": "19px",
                                      "color" : "#FCFCFC",
                                      "font-weight": "400"
                                    }
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Zurück zur ARIEL website",
                              "grid": {
                                "columns": 6,
                                "alignItems": "center",
                                "justifyContent": "center",
                                marginBottom: "50px"
                              },
                              "classes": "btn-primary",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "https://www.ariel.de/de-de"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms Page
        "pageName": "gratis/terms",
        isPublic: true,
        pageId: 1217,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Embed component
                      "fieldType": "pdf-viewer",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/gratis/terms.pdf',
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // FAQ Page
        "pageName": "gratis/faq",
        isPublic: true,
        pageId: 1218,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Embed component
                      "fieldType": "pdf-viewer",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/faq.pdf',
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign is Over
        "pageName": "gratis/campaign-is-over",
        isPublic: true,
        pageId: 1208,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Der Aktionszeitraum ist vorbei",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: "Registrieren Sie sich, um auf dem aktuellsten Stand zu bleiben. Erhalten Sie Sonderangebote und Updates.",
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/over.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur ARIEL website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.ariel.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Error
        "pageName": "gratis/error",
        isPublic: true,
        pageId: 12082,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Oops...",
                                    classes: 'heading text-left',
                                    styles: {
                                      "font-size": "56px",
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: "Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut.",
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/error.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur ARIEL website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.ariel.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired
        "pageName": "gratis/expired",
        isPublic: true,
        pageId: 1210,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Diese Sitzung ist abgelaufen",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: `Leider ist der Link nicht mehr gültig! Bitte nehmen Sie erneut teil oder kontaktieren Sie unser Support Team: <a href='mailto:gratis2024@ariel-testen.de'>gratis2024@ariel-testen.de</a>`,
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/over.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Startseite",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product ReUpload
        "pageName": "gratis/product-reupload",
        isPublic: true,
        pageId: 1214,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "classes": "d-flex flex-column",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                          {
                            query: "campaignId",
                            state: "campaignId"
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Produktfoto hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        text: `Bitte laden Sie in diesem Schritt ein Foto der Rückseiten Ihrer Aktionsprodukte hoch. Bitte beachten Sie, dass die EAN Codes gut zu erkennen sein müssen.
                        <br><br>
                        Falls Sie mit mehr als einem Produkt teilnehmen möchten, stellen Sie bitte sicher, dass die Rückseiten aller auf Ihrem Kassenbeleg gelisteten Produkte gut zu erkennen und auf dem selben Foto sind.`,
                        classes: 'subHeading text-left'
                      }
                    }
                  ]
                }
              },
              { // Product Photo uploader
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "justifyContent": "center",
                    "alignItems": "center",
                    "columnGap": "20px",
                    "marginBottom": "50px"
                  },
                  "fields": [
                    { // Product photo upload
                      "fieldType": "product-upload",
                      "fieldId": "prodPhotoRe",
                      "properties": {
                        "grid": {
                          "columns": 4,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        texts: {
                          choose: 'Upload',
                          text1: 'Drop your photo here or select file.',
                          text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                          wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                          fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                        },
                        "colorSchema": "var(--color2)",
                        reUpload: true,
                        "endpoint": "/reuploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "gratis/thank-you"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 8,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "flexDirection": "column"
                        },
                        "fields": [
                          {
                            "fieldType": "product-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "texts": {
                                desc: "",
                                subDesc: ""
                              },
                              "image": "assets/images/png/product.png"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Buttons
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12",
                    justifyContent: "center",
                    "marginBottom": "30px",
                  },
                  classes: "btn-bucket",
                  fields: [
                    { // Continue Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 3
                        },
                        text: "Weiter",
                        classes: "btn-primary",
                        "requires": ["prodPhotoRe"],
                        "action": {
                          "fn": "sendProductPhotos",
                          "params": "prodPhotoRe"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt reUpload
        "pageName": "gratis/receipt-reupload",
        isPublic: true,
        pageId: 1015,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                          {
                            query: "campaignId",
                            state: "campaignId"
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Kaufbeleg hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie in diesem Schritt den Kaufbeleg Ihrer Produkte hoch.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "receiptRe",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "gratis/thank-you"
                              },
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              colorSchema: "#DF1B25",
                              reUpload: true,
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information-old",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["receiptRe"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product ReUpload new 
        "pageName": "gratis/product-re-upload",
        isPublic: true,
        pageId: 1214,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "classes": "d-flex flex-column",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                          {
                            query: "campaignId",
                            state: "campaignId"
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Produktfoto hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        text: `Bitte laden Sie ein Foto von der Rückseite Ihres Aktionsproduktes hoch.`,
                        classes: 'subHeading text-left'
                      }
                    }
                  ]
                }
              },
              { // Product Photo uploader
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "justifyContent": "center",
                    "alignItems": "center",
                    "columnGap": "20px",
                    "marginBottom": "50px"
                  },
                  "fields": [
                    { // Product photo upload
                      "fieldType": "product-upload",
                      "fieldId": "prodPhotoRe",
                      "properties": {
                        "grid": {
                          "columns": 4,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        texts: {
                          choose: 'Upload',
                          text1: 'Drop your photo here or select file.',
                          text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                          wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                          fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                        },
                        "colorSchema": "var(--color2)",
                        reUpload: true,
                        "endpoint": "/reuploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "gratis/thank-you"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 4,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "flexDirection": "column"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: `Stellen Sie sicher, dass der Barcode ihres Aktionsproduktes gut sichtbar ist.`,
                              classes: 'subHeading text-center',
                              styles: {
                                fontSize: '16px'
                              }
                            }
                          },
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 6,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/product.png",
                              "alternateText": "product"
                            }
                          },
                        ]
                      }
                    }
                  ]
                }
              },
              { // Buttons
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12",
                    justifyContent: "center",
                    "marginBottom": "30px",
                  },
                  classes: "btn-bucket",
                  fields: [
                    { // Continue Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 3
                        },
                        text: "Weiter",
                        classes: "btn-primary",
                        "requires": ["prodPhotoRe"],
                        "action": {
                          "fn": "sendProductPhotos",
                          "params": "prodPhotoRe"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt reUpload new
        "pageName": "gratis/receipt-re-upload",
        isPublic: true,
        pageId: 1015,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                          {
                            query: "campaignId",
                            state: "campaignId"
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Kaufbeleg hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie in diesem Schritt den Kaufbeleg Ihrer Produkte hoch.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "receiptRe",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              "colorSchema": "var(--color2)",
                              reUpload: true,
                              "endpoint": "/reuploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "gratis/thank-you"
                              }
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information-old",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["receiptRe"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "receiptRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Product Upload
        "pageName": "gratis/receipt-product-re-upload",
        isPublic: true,
        pageId: 1015,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                          {
                            query: "campaignId",
                            state: "campaignId"
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Kaufbeleg + Produktrückseite mit Barcode hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: `Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes, gemeinsam mit der Rückseite des Produktes hoch. Bitte beachten Sie, dass der Barcode und das Aktionsprodukt gut ersichtlich sein müssen.`,
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "receiptRe",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "gratis/thank-you"
                              },
                              postBillId: 'pg-dach-2024-2',
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              colorSchema: "#DF1B25",
                              reUpload: true,
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["receiptRe"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // DOI
        pageName: "gratis/doi",
        pageId: 125,
        isPublic: true,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              justifyContent: "center",
              alignItems: "center",
              margin: 0
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // DOI CHECK
                fieldType: "doi",
                fieldId: "doiasdasda",
                properties: {
                  endpoint: '/checkDoi'
                }
              },
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "80px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 6,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        fields: [
                          { // Heading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              text: "Danke für Ihre Anmeldung",
                              classes: 'heading-black text-left'
                            }
                          },
                          { // Subheading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              text: "Sie haben sich erfolgreich für unseren Newsletter angemeldet. Ab sofort erfahren Sie als Eine/Einer der Ersten, sobald des kostenlose Produkttests, Gewinnspiele und inspirierende Artikel gibt.",
                              classes: 'subHeading text-left'
                            }
                          }
                        ]
                      }
                    },
                    { // Thank you image mobile
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/thanks.png",
                              "alternateText": "SMS",
                              "classes": "sms-image",
                              "styles": {
                                "max-width": "250px"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // Section
            grid: {
              columns: 12,
              alignItems: "center",
              justifyContent: "center"
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "60px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 8,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        classes: "container",
                        fields: [

                        ]
                      }
                    },
                    { // Key visual
                      fieldType: "key-visual",
                      fieldId: "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          padding: '0 0 0 0',
                          maxWidth: '75vw'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/gratis/banner-desktop.jpg",
                        tabletImageUrl: "assets/images/banner/gratis/banner-desktop.jpg",
                        mobileImageUrl: "assets/images/banner/gratis/banner-mobile.jpg"
                      }
                    },
                    { // Container
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 12,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "60px"
                        },
                        classes: "container",
                        fields: [
                          { // Buttons
                            fieldType: "empty-bucket",
                            fieldId: "fu0923r",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              classes: "btn-bucket",
                              fields: [
                                { // Continue Button
                                  fieldType: "button-component",
                                  fieldId: "but100",
                                  properties: {
                                    grid: {
                                      columns: 8
                                    },
                                    text: "Zurück zur ARIEL website",
                                    classes: "btn-primary",
                                    action: {
                                      fn: "goToLink",
                                      params: "https://www.ariel.de/de-de"
                                    },
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN Reupload
        "pageName": "gratis/iban-reupload",
        pageId: 120312,
        isPublic: true,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "customerToken",
                            state: "customerToken"
                          },
                          {
                            query: 'campaignId',
                            state: 'campaignId'
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "iban-reupload",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bankverbindung angeben",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte teilen Sie uns mit, auf welches Konto der Erstattungsbetrag überwiesen werden soll.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "ibanReForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/updateIban",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "gratis/iban-thank-you"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldId": "b4baqs",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "firstname",
                                                "id": "firstname",
                                                "label": "Vorname Kontoinhaber",
                                                "placeholder": "Vorname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofije",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "lastname",
                                                "id": "lastname",
                                                "label": "Nachname Kontoinhaber",
                                                "placeholder": "Nachname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "b4baqq",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "iban",
                                                "id": "iban",
                                                "label": "IBAN",
                                                "placeholder": "IBAN",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|iban",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofijx",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "bic",
                                                "id": "bic",
                                                "label": "BIC",
                                                "placeholder": "BIC",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|bic|bic-BOFS",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "text": '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                                          classes: 'subHeading text-left',
                                          styles: {
                                            "font-size": "14px",
                                            "font-weight": "400"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "ibanReForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN Thank You
        "pageName": "gratis/iban-thank-you",
        pageId: 1209,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/png/products.png",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "400px"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: "Danke für die Teilnahme",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: `Vielen Dank, dass Sie Ihre Bankdaten erneut eingereicht haben. Einer erfolgreichen Überweisung sollte also nichts mehr im Wege stehen. Sollten Sie Fragen haben, die mit Ihren Bankdaten zusammenhängen, wenden Sie sich bitte an <a href='mailto:iban-milde-frische@ariel-testen.de'>iban-milde-frische@ariel-testen.de</a> Für sonstige Angelegen können Sie unseren Support Service unter <a href='mailto:milde-frische@ariel-testen.de'>milde-frische@ariel-testen.de</a> kontaktieren.`,
                        classes: 'subHeading text-center'
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Startseite",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms and Conditions
        "pageName": "gratis/terms-and-conditions",
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
            },
            "fields": [
              {
                "fieldType": "terms-component",
                "fieldId": "a6prst"
              }
            ]
          }
        ]
      },
    ],
    "header": {
      "fieldType": "navbar-new",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "logo": {
          imageSource: "assets/images/png/logo.png",
          url: '/',
          alt: 'Everyday Logo'
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "fields": [
          {
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": "Teilnahmebedingungen",
              "url": "/gratis/terms",
              "target": "_blank",
              "cssClass": "nav-link headingLink",
              "styles": {
                'color': '#006A42 !important',
                'text-decoration': 'none !important',
                'font-weight': 'bold !important',
                'width': 'max-content'
              }
            }
          },
          {
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": "FAQ",
              "url": "/gratis/faq",
              "target": "_blank",
              "cssClass": "nav-link headingLink",
              "styles": {
                'color': '#006A42 !important',
                'text-decoration': 'none !important',
                'font-weight': 'bold !important',
                'width': 'max-content'
              }
            }
          }
        ],
        "meta": [
          { "name": "title", "content": "Ariel Gratis Testen" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `Ariel, Gratis, Testen, Ariel Gratis Testen, Ariel Gratis Testen`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
    },
    errors: {
      de: {
        default: "Hoppla, da ist was schief gelaufen!",
        noEmail: "Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.",
        emailUsed: "Diese E-Mail-Adresse wird bereits verwendet",
        incorrect: "Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!",
        invalidCode: "Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.",
        manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode: 'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network: 'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        '15minsOver': 'Die Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
        notActive: 'Ungültiger Teilnahmezeitraum. Bitte lesen Sie unsere Teilnahmebedingungen.',
        badRequest: 'Ungültige Anfrage: campaignKey ist erforderlich.',
        alreadyActive: 'Pro IP-Adresse ist nur eine Teilnahme innerhalb von 15 Minuten erlaubt. Bitte probieren Sie es in ein paar Minuten erneut.',
        dailyParticipationLimit: 'Das tägliche Limit wurde leider schon erreicht, aber es ist möglich, dass Plätze wieder frei werden, falls einige Kaufbelege abgelehnt werden. Bleiben Sie dran - vielleicht klappt es ja doch noch! Ansonsten können Sie es gerne morgen erneut versuchen. Der Zähler wird um 8 Uhr zurückgesetzt.'
      }
    },
    "url": "http://justsnap.everydayme.pl/",
    "name": "test-campaign"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: "ariel-mild-scent",
  campaignKey: "753788f1-751d-45f1-a07f-b49798b84f6c",
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignEndDate: '2025-06-30',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "",
  errorActivate: false,
  scannedProducts: [],
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  remainingCount: 0,
  jwtToken: '',
  guestSelected: null,
  allowedPages: ['campaign-is-over', 'terms', 'faq', 'receipt-reupload', 'product-reupload', 'iban-reupload', 'error', 'expired']
}