<template>
  <div class="campaignWrapper">
    <div class="container">
      <h3 class="separator">NEUE AKTIONEN</h3>
      <div
        class="row justify-content-center"
        :style="activeCampaigns.length ? 'margin-top: 50px' : ''"
      >
        <div
          class="col-lg-6 col-12 campaign"
          :key="campaign.name"
          v-for="campaign in activeCampaigns"
        >
          <p class="title" v-html="campaign.name"></p>
          <div class="shadow">
            <img
              class="visual"
              :src="campaign.visual"
              :alt="campaign.name"
              :class="campaign.className != null ? campaign.className : ''"
            />
          </div>
          <div class="campaignDuration" v-if="campaign.dateText1">
            {{ campaign.dateText1 }} | {{ campaign.dateText2 }}
          </div>
          <div class="row contentWrapper">
            <div class="col-12 d-flex justify-content-center">
              <buttonComponent
                :grid="{
                  columns: '12',
                  alignItems: 'center',
                  justifyContent: 'center',
                }"
                addClass="multi-campaign"
                name="TEILNEHMEN"
                @click.native="goToCampaign(campaign)"
                text="TEILNEHMEN"
              />
            </div>
          </div>
        </div>
      </div>
      <h3 v-if="endedCampaigns.length" class="separator">
        BEENDETE AKTIONEN
      </h3>
      <div class="row" style="margin-top: 50px">
        <div
          v-for="(campaign, idx) in endedCampaigns"
          class="col-lg-4 col-12 campaign"
          :key="campaign.name"
          :class="{ active: hoveringIndex == idx }"
          @mouseover="hoveringIndex = idx"
          @mouseleave="hoveringIndex = -1"
        >
          <p class="title" v-html="campaign.name"></p>
          <div class="shadow">
            <img
              class="visual"
              :src="campaign.visual"
              :alt="campaign.name"
              :style="'mix-blend-mode: luminosity;'"
            />
          </div>
          <div class="row contentWrapper">
            <div class="col-12 d-flex justify-content-center">
              <buttonComponent
                :grid="{
                  columns: '12',
                  alignItems: 'center',
                  justifyContent: 'center',
                }"
                addClass="btn multi-campaign"
                name="EINZELHEITEN"
                @click.native="goToCampaign(campaign)"
                text="TEILNAHMEBEDINGUNGEN"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonComponent from "./button-component.vue";
import moment from "moment";
import { localize } from "vee-validate";

export default {
  name: "campaign-grid",
  components: {
    buttonComponent,
  },
  data: () => {
    return {
      campaigns: [
        {
          // Gratis
          name: "Ariel Frische Baumwollblüte Gratis Testen Aktion",
          shortName: "gratis",
          url: "gratis",
          termsLink: 'terms',
          visual: "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/gratis/visuals/card-visual.svg",
          visualMobile: "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/gratis/visuals/card-visual.svg",
          className: "",
          endDate: "2025-06-15",
          lastUploadDate: "2025-06-30",
          startDate: "2025-02-01", // change before go live
          dateText1: "Produktkauf bis: 15.06.2025",
          dateText2: "Aktionsschluss: 30.06.2025",
        },
        {
          // Unstoppables
          name: "Ariel Touch of Unstoppables Gratis Testen Aktion",
          shortName: "unstoppables",
          termsLink: 'terms',
          url: "unstoppables",
          visual:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/unstoppables/visuals/card-visual.svg",
          visualMobile:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/ariel-testen/unstoppables/visuals/card-visual.svg",
          className: "",
          endDate: "2025-03-15",
          lastUploadDate: "2025-03-31",
          startDate: "2024-08-01",
          dateText1: "Produktkauf bis: 15.03.2025",
          dateText2: "Aktionsschluss: 31.03.2025",
        },
      ],
      selectedCategory: 0,
      hoveringIndex: -1,
    };
  },
  props: {
    requires: {
      type: Array,
    },
    grid: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    activeCampaigns() {
      return this.campaigns.filter(
        (x) =>
          !this.checkEnded(x.lastUploadDate) && this.checkStarted(x.startDate)
      );
    },
    endedCampaigns() {
      const ended = this.campaigns.filter((x) => {
        const isEnded = this.checkEnded(x.lastUploadDate);
        return isEnded;
      });
      return ended;
    },
    filteredCampaigns() {
      if (this.selectedCategory === 0) {
        return this.activeCampaigns;
      } else {
        return this.endedCampaigns;
      }
    },
  },
  methods: {
    async goToCampaign(cam) {
      if (cam.external) {
        window.open(cam.externalUrl, "_blank");
      } else {
        // Import the campaign file dynamically
        const campaign = await require(`../../store/campaigns/${cam.shortName}`)
          .default;
        campaign.pageProperties.testing =
          this.$store.state.pageProperties.testing;
        this.$store.replaceState(campaign);
        this.initState(this.$store.state.pageProperties);

        setTimeout(() => {
          let ended = this.checkEnded(cam.lastUploadDate);
          if (ended) {
            this.goToPage(`${cam.url}/${cam.termsLink}`, this);
          } else {
            this.goToPage(cam.url, this);
          }
        }, 10);
      }
    },
    checkEnded (date) {
      let valid = moment(date).format('YYYY-MM-DD') != 'Invalid date';
      if (!valid) {
        if (moment(date, 'DD.MM.YYYY').creationData().format == 'DD.MM.YYYY') {
          date = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
        }
      }
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Berlin"
      });
      const isTestVersion = process.env.NODE_ENV != 'production' || this.$route.query['version'] === 'test'
      const dayInSeconds = 86400; // If we need to include the ending day
      if ((new Date(date).getTime() / 1000 + dayInSeconds < new Date(currentTime).getTime() / 1000) && !isTestVersion) {
        return true
      } else {
        return false
      }
    },
    checkStarted(date) {
      let valid = moment(date).format("YYYY-MM-DD") != "Invalid date";
      if (!valid) {
        if (moment(date, "DD.MM.YYYY").creationData().format == "DD.MM.YYYY") {
          date = moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
        }
      }
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Berlin",
      });
      const isTestVersion =
        process.env.NODE_ENV != "production" ||
        this.$route.query["version"] === "test";
      if (
        new Date(date).getTime() / 1000 <
          new Date(currentTime).getTime() / 1000 ||
        isTestVersion
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectCategory(categoryId) {
      this.selectedCategory = categoryId;
    },
    initState(pageProperties) {
      if (pageProperties.dictionary) {
        // merges with built-in phrases
        localize(pageProperties.dictionary);
      }
      if (
        pageProperties.header &&
        pageProperties.header.properties.facebookPixel
      ) {
        const facebookConfig = pageProperties.header.properties.facebookPixel;
        this.addFacebookPixel(facebookConfig);
      }

      if (pageProperties.header && pageProperties.header.properties.chat) {
        const chat = pageProperties.header.properties.chat;
        if (chat.control) {
          this.addHTML(chat.control);
        }
        if (chat.js) {
          this.addJsFile(chat.js);
        }
        if (chat.css) {
          this.addCssFile(chat.css);
        }
      }

      if (pageProperties.header && pageProperties.header.properties.meta) {
        const meta = pageProperties.header.properties.meta;
        if (meta) {
          this.meta = meta;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaignDuration {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 0px 0px;
}
.campaignWrapper {
  margin: 20px 0 30px 0;

  @media (max-width: 992px) {
    margin: 40px 0 0;
  }
}

.campaign {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 40px;

  .visual {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .shadow {
    border-radius: 20px;
  }

  .title {
    margin: 0;
    padding: 5px;
    font-size: 22px;
    height: 70px;
    text-align: center;
    color: #2c3e50 !important;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 20px;
    }
    @media (max-width: 520px) {
      font-size: 17px;
    }
    @media (max-width: 399px) {
      padding: 0;
      margin-bottom: 10px;
    }
  }

  &.active {
    .visual {
      mix-blend-mode: normal !important;
    }
  }

  button {
    margin-top: 20px;

    @media (max-width: 550px) {
      max-width: 100% !important;
      width: 100%;
    }
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #006a42;
  font-size: 26px;
  line-height: 33px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d9d9d9;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
.height-helper {
  height: 275px !important;
}
</style>
