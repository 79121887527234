import Vue from 'vue';
import { getAuth0Client } from './authWrapper';
const auth = new Vue({
  data () {
    return {
      isAuthenticated: false,
      user: null,
      auth0Client: null,
      error: null,
      token: null,
      loading: true,
      appState: {}
    };
  },
  methods: {
    async loginWithRedirect (options) {
      return this.auth0Client.loginWithRedirect(options);
    },
    logout (options) {
      return this.auth0Client.logout(options);
    },
    async handleRedirectCallback () {
      try {
        const result = await this.auth0Client.handleRedirectCallback();        
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.token = await this.auth0Client.getTokenSilently();
        return result;
      } catch (e) {
        this.error = e;
      }
      finally {
        this.loading = false;
      }
    },
    async checkUser () {
      try {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          this.user = await this.auth0Client.getUser();
          this.token = await this.auth0Client.getTokenSilently();
        }
      } catch (e) {
        this.error = e;
      }
      finally {
        this.loading = false;
      }
    },
    // async checkUser() { // Try later: check active session first
    //   try {
    //     const token = await this.auth0Client.getTokenSilently();
        
    //     if (token) {
    //       
    //       this.isAuthenticated = true;
    //       this.user = await this.auth0Client.getUser();
    //       this.token = token;
    //     } else {
    //       
    //       this.isAuthenticated = false;
    //       this.user = null;
    //       this.token = null;
    //     }
    //   } catch (e) {
    //     
    //     console.error('Error during session check:', e);
    //     this.isAuthenticated = false;
    //     this.user = null;
    //     this.token = null;
    //   } finally {
    //  
    //     this.loading = false;
    //   }
    // }
  },
  async created () {
    this.auth0Client = await getAuth0Client();
    await this.checkUser();
  },
});
export default {
  install (Vue) {
    Vue.prototype.$auth = auth;
  },
};
export { auth };