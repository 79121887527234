<template>
  <div>
    <page v-if="ready" :page-id="1" />
    <div v-else class="loader">Loading...</div>
  </div>
</template>

<script>
import page from "@/components/page.vue";
import { mapActions } from "vuex";
import { localize } from "vee-validate";

export default {
  data() {
    return {
      ready: false,
      meta: [],
    };
  },
  components: {
    page,
  },
  created() {
    console.log(this.$auth.token);
    let pageProperties = this.$store.state.pageProperties;

    const receiptToken = sessionStorage.getItem("receiptToken");
    const customerToken = sessionStorage.getItem("customerToken");
    const jwtToken = sessionStorage.getItem("jwtToken");

    this.$store.commit("setReceiptToken", receiptToken);
    this.$store.commit("setCustomerToken", customerToken);
    this.$store.commit("setJwtToken", jwtToken);

    console.log("AUTH", receiptToken);
    console.log("AUTH", customerToken);
    if (process.env.NODE_ENV === "development") {
      pageProperties.testing = true;
    } else {
      pageProperties.testing = false;
    }

    if (pageProperties == "GEOLIMIT") {
      this.$router.push("/geoLimit");
      // this.ready=true;
      return;
    }

    if (pageProperties == "NOT_FOUND" || !pageProperties.pages) {
      this.$router.push("/404");
      // this.ready=true;
      return;
    }

    /**
     * Check if given url pathname is in pages database and its public,
     * otherwise redirect to error-page
     */
    const pathIsEmpty = this._.isEmpty(this.$route.params);
    if (
      (!pathIsEmpty && this.$route.params) ||
      this.$route.path === `/${this.$route.params.campaign}/bank`
    ) {
      let path =
        this.$route.params.pathMatch || `${this.$route.params.campaign}/bank`;
        console.log("FIRST PATH", path)
      this.isPublic(path).then((pageIsPublic) => {
        console.log("PATH:", path, "IS PUBLIC:", pageIsPublic);
        if (pageIsPublic) {
          console.log("Page is public, navigating to:", path);
          this.goToPage(path, this);
          return;
        } else {
          console.log("Page is NOT public");
          if (path.includes("/")) {
            console.log("Path includes slash, navigating to:", path.split("/")[0]);
            this.goToPage(path.split("/")[0], this);
          } else {
            console.log("Replacing state to root");
            history.replaceState({}, null, "/");
          }
          return;
        }
        if (path.includes("/")) {
          this.goToPage(`${path.split("/")[0]}/error`, this);
        } else {
          this.goToPage("error", this);
        }
      });
    }

    if (this.$te("dictionary")) localize(this.$t("dictionary"));

    if (pageProperties.header && pageProperties.header.properties.meta) {
      const meta = pageProperties.header.properties.meta;
      if (meta) {
        this.meta = meta;
      }
    }

    this.ready = true;
    document.dispatchEvent(new Event("x-app-rendered"));
  },
  methods: {
    ...mapActions(["isPublic"]),
    addCssFile(url) {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = url;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    addJsFile(url) {
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("charset", "UTF-8");
      script.setAttribute("src", url);
      document.head.appendChild(script);
    },
    addHTML(html) {
      document.body.insertAdjacentHTML("beforeend", html);
    },
    setGlobalFont(font) {
      document.getElementById("app").style.fontFamily = font;
    },
    addNeustar(cfg) {
      let N = document.createElement("script");
      N.setAttribute("type", "text/javascript");
      N.setAttribute("charset", "UTF-8");
      N.textContent = `   window.nsIdPending = false;
        function OptanonWrapper() {
          Optanon.OnConsentChanged(()=>{
          if(!window.nsId && !window.nsIdPending ){
              window.nsIdPending = true;
          fetchJsonp('${cfg.url}', {
                  jsonpCallbackFunction: 'neustar_response'
              })
              .then(function(response) {
                  return response.json()
              }).then(function(json) {
                  window.nsId = json.nsid
              })

          if (window._agknEchoTag) {
                  var a = window._agknEchoTag;
                  a.setBpId('firstp');
                  a.addEchoKeyValue('event', 'page_landing');
                  a.generateTag();
              }}
              })
      }`;
      document.head.appendChild(N);
    },
    injectJs(cfg) {
      let tag = document.createElement("script");
      tag.setAttribute("type", "text/javascript");
      tag.setAttribute("charset", "UTF-8");
      tag.textContent = cfg.content;
      if (cfg.location === "body") document.body.append(tag);
      else document.head.appendChild(tag);
      // console.log('inject',tag)
    },
    addFacebookPixel(cfg) {
      // console.log(">>>",cfg)
      let fbF = document.createElement("script");
      fbF.setAttribute("type", "text/javascript");
      fbF.setAttribute("charset", "UTF-8");
      fbF.textContent = `    !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${cfg.init}');
  fbq('track', 'PageView');
  `;
      document.head.appendChild(fbF);
      let fbNs = document.createElement("noscript");
      fbNs.textContent = `<img height="1" width="1" style="display:none" src="${cfg.noscript}" alt=""/>`;
      document.head.appendChild(fbNs);
    },
    addGTM(config) {
      const GTM = config.GTM;
      const GTM_CODE = config.GTM_CODE.toString();
      let GTM_TAG = document.createElement("script");
      GTM_TAG.setAttribute("type", "text/javascript");
      GTM_TAG.setAttribute("charset", "UTF-8");
      GTM_TAG.textContent = "const PGdataLayer =" + JSON.stringify(config);
      // GTM_TAG.textContent = `const PGdataLayer ={'GTM':{'SiteLocalContainer':'${GTM.SiteLocalContainer}','FacebookRemarketingID':'${GTM.FacebookRemarketingID}','SiteLocale': 'de-de','SiteTouchpoint': '','SiteCountry': 'DE','SiteBrand': '','SiteLanguage': 'DE','SitePlatform': '','SiteEnvironment': 'Prod','SiteHost': 'CTL','SiteTechnicalAgency': 'Justsnap','SiteStatus': 'Live','GoogleAnalyticsLocal': '${GTM.GoogleAnalyticsLocal}','GoogleAnalyticsBrand': '','GoogleAnalyticsSiteSpeedSampleRate': 'high','GoogleAnalyticsAllowLinker': 'true','GoogleAnalyticsLinkerDomains': '','GoogleAnalyticsEnableOptimizely': 'false','GoogleAnalyticsConsentRequired': 'true','GoogleAnalyticsOptimizeContainerID': '','GoogleAnalyticsReportingView': '','GoogleReCaptcha': 'true', "ConsentOverlay": "${GTM.ConsentOverlay}", "ConsentOverlayID": "${GTM.ConsentOverlayID}","SitePrivacyProtection": "${GTM.SitePrivacyProtection}" }}`;
      let GTM_TAG_FN = document.createElement("script");
      GTM_TAG_FN.setAttribute("type", "text/javascript");
      GTM_TAG_FN.setAttribute("charset", "UTF-8");
      GTM_TAG_FN.textContent = `

       (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${GTM_CODE}');
      `;

      document.getElementsByTagName("head")[0].append(GTM_TAG);
      document.getElementsByTagName("head")[0].append(GTM_TAG_FN);
    },
    setFavIcon(favicon) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = require(`@/${favicon.imageSource}`);
    },
  },
  metaInfo() {
    return {
      title: this.meta.length > 0 ? this.meta[0].content : "",
      meta: this.meta.length > 0 ? this.meta : [],
    };
  },
};
</script>
<style>
/** for chat bot */
#lc.minimized {
  display: none;
}
</style>
<style lang="scss" scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgb(45, 41, 41);
  border-right: 1.1em solid rgb(45, 41, 41);
  border-bottom: 1.1em solid rgb(45, 41, 41);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
$medium: 992px;
.mobile-hide {
  @media only screen and (max-width: $medium) {
    display: none;
  }
}
</style>
