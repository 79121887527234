<template>
  <nav class="navbar-wrapper" :style="[cssVars, styles]">
    <div
      class="navbar navbar-expand-lg navbar-light p-0 container"
      :class="{'justify-content-end' : removeLogo , 'justify-content-between' : !removeLogo}"
    >
      <a
        v-if="!removeLogo"
        class="navbar-brand"
        href="/"
        aria-label="homepage button"
      >
        <img
          :src="require(`@/${logo.imageSource}`)"
          :width="logo.width"
          :height="logo.height"
          :alt="logo.alt"
        />
      </a>
      <div class="justify-content-center d-none d-lg-flex d-xl-flex">
        <h1
          class="m-0 p-0"
          :style="`line-height: 1; font-size: 30px; color: ${color};`"
        >
          {{ title }}
        </h1>
      </div>

      <button
        v-if="inCampaign"
        class="navbar-toggler"
        type="button"
        @click="checkBoxClicked"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse expandedDiv" ref='expandedDiv' >
        <ul class="navbar-nav">
          <li
            class="navbarLinks"
            v-for="field in fields"
            :key="field.fieldId"
          >
            <componentRenderer v-bind:key="field.id" :field="field" />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "navbar-new",
  components: {
    componentRenderer,
  },
  props: {
    inCampaign: {
      type: Boolean,
      default: true,
      required: false
    },
    styles: {
      type: Object,
      default: () => {
        return {
          "background": "#FFF",
          "color": "#000"
        }
      }
    },
    showLine: {
      required: false,
      type: Boolean
    },
    backgroundColor: {
      required: false,
      type: String,
      default: "#fff"
    },
    title: {
      required: false,
      type: String,
      default: ""
    },
    logo: {
      required: true,
      type: Object
    },
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    color: {
      required: false,
      type: String,
      default: "#000"
    },
    hasLanguageBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    removeLogo () {
      return this.$store.getters.removeLogo;
    },
    cssVars () {
      return {
        '--color': this.styles.color,
        '--bg-color': this.styles.background
      }
    }
  },
  methods: {
    checkBoxClicked () {
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + "px";
    }
  },
  watch: {
    currentPage (val) {
      console.log("page change", val)
    }
  }
};
</script>

<style lang="scss" scoped>

.navbar-brand {
  padding: 10px 0;
  img {
    max-width: 91px;
    margin: 0 20px;
    @media(max-width: 450px){
      // max-width: 90px;
      margin: 0px !important;
    }
  }
}

.navbarLinks {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 200px;
  min-height: 65px;
}

.navbar-wrapper {
  position: relative;
}

.hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
.navbar-collapse {
  flex-grow: initial;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(13,34,84, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-expand-lg {
  min-height: 65px;
}

.expandedDiv {

  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
    -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
    -o-transition: height 0.4s ease-out;;  /* Opera */
  }
}
</style>
