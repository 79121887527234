/* eslint-disable */

<template>
  <div>
    <Loading v-if="isLoading" />
    <router-view v-else />
    <input
      name="btnOneTrust"
      type="button"
      id="btnOneTrust"
      class="btnOneTrust"
      value="Meine Cookie Auswahl"
      onclick="Optanon.ToggleInfoDisplay();"
      aria-label="Cookie Button"
    >
  </div>
</template>
<script>
const version = process.env.VUE_APP_VERSION;
import Loading from "./components/loading.vue";
export default {
  components: { Loading},
  computed: {
    isLoading () {
      return this.$auth.loading
    }
  },
  mounted() {
    window.addEventListener('urlchange', this.checkUrl);
  },
  beforeDestroy() {
    window.removeEventListener('urlchange', this.checkUrl);
  },
  created () {
    // Remove console logs when in production
    if (process.env.NODE_ENV !== "development" && this.$route.query.version != "test") {
      console.log = function () { };
    }
    console.log("%cV" + version, "color:red; font-size:32px;");
  },
  methods: {
    // TODO: Catch statement added because of node js
    oneTrustModal: function () {
      // eslint-disable-next-line no-undef
      Optanon.OnConsentChanged(() => {
        if (window.segmentaCookieHandler) window.segmentaCookieHandler();
        if (!window.nsId && !window.nsIdPending && window.nsUrl) {
          window.nsIdPending = true;
          // eslint-disable-next-line no-undef
          fetchJsonp(window.nsUrl, {
            jsonpCallbackFunction: "neustar_response"
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              window.nsId = json.nsid;
            })
            .catch(function (er) {
              console.log(er)
            });

          if (window._agknEchoTag) {
            var a = window._agknEchoTag;
            a.setBpId("firstp");
            a.addEchoKeyValue("event", "page_landing");
            a.generateTag();
          }
        }
      });
      // eslint-disable-next-line
      Optanon.ToggleInfoDisplay();
    },
    checkUrl() {
      const appElement = document.getElementById('app');
      if (location.pathname === '/') {
        appElement.style.background = '#fff';
      } else {
        appElement.style.background = 'linear-gradient(176deg, #165D53 3.27%, #3A855C 32.63%, #3A855C 98.81%)';
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/styles/_application.scss";

#btnOneTrust {
  display: block!important;
  position: fixed;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
}
</style>
