import { createAuth0Client } from '@auth0/auth0-spa-js';
let auth0Client = null;
export const getAuth0Client = async () => {
  if (auth0Client) return auth0Client;
  auth0Client = await createAuth0Client({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin + '/login/callback',
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
      scope: 'openid profile email read:messages'
    },
    cacheLocation: 'localstorage'
  });
  return auth0Client;
};