<template>
  <span></span>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SignInOkta",
  async mounted() {
  if (this.$store.getters.guestSelected) {
    return;
  }
  
  try {
    const backupAccessToken = sessionStorage.getItem('accessToken');
    const signInResponse = await this.$store.dispatch("signInWithOkta", {
      accessToken: this.$auth.token,
      backupAccessToken: backupAccessToken
    });
    
    this.$store.commit("setReferenceNumber", signInResponse.referenceNumber);
    
    if (this.$auth.isAuthenticated && !signInResponse.response) {
      try {
        this.$store.commit('setModalOptions', {
          type: 'signInErr',
          isActive: true,
          message: 'Es ist ein Fehler aufgetreten, da Ihre Verbindungen und Daten nicht gesendet werden konnten. Bitte versuchen Sie es erneut.'
        });
      } catch (err) {
        console.error(err);
      }
    }
  } catch (error) {
    console.error("Sign-in failed:", error);
    
    if (this.$auth.isAuthenticated) {
      try {
        this.$store.commit('setModalOptions', {
          type: 'signInErr',
          isActive: true,
          message: 'Es ist ein Fehler aufgetreten, da Ihre Verbindungen und Daten nicht gesendet werden konnten. Bitte versuchen Sie es erneut.'
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
};
</script>
