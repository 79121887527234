<template>
  <div class="loading">
    <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="danger"></b-spinner>
    <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="danger"></b-spinner>
    <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="danger"></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100vh;
  font-size: 1.5rem;
  color: #333;
}
</style>
