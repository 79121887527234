<template lang="">
  <Loading v-if="loading" />
</template>
<script>
import Loading from "../components/loading.vue"
export default {
  name: "LoginCallback",
  components: {Loading},
  data() {
    return {
      loading: true,
    };
  },
  async created () {
    try {
      const callbackResult = await this.$auth.handleRedirectCallback();
      const appState  = callbackResult.appState;
      console.log("appState:", appState);
      if (this.$auth.isAuthenticated) {
        sessionStorage.setItem('receiptToken', appState.receiptToken)
        sessionStorage.setItem('customerToken', appState.customerToken)
        sessionStorage.setItem('jwtToken', appState.jwtToken)
        sessionStorage.setItem('accessToken', this.$auth.token)
        // this.$store.commit('setReceiptToken', appState.receiptToken)
        // this.$store.commit('setCustomerToken', appState.customerToken)
        // this.$store.commit('setJwtToken', appState.jwtToken)
        window.location.href = appState.targetUrl
      } else {
        throw new Error("User not authenticated");
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      // Navigate to the error page
      this.$router.push({ path: "/error" });
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style lang=""></style>