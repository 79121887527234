import axios from 'axios'
import i18n from '../i18n'

const APIUrl = process.env.VUE_APP_API_URL
const APIUrlv3 = process.env.VUE_APP_API_URL_V3
// const APIUrl = "http://localhost:3000/api"

export default {
  isPublic({ state }, pageName) {
    if (pageName.toLowerCase() === 'home') return true
    const page = state.pageProperties.pages.find(page => {
      return page.pageName === pageName
    })
    return page && page.isPublic;
  },
  handleError: ({ commit, state }, err = { message: '', code: '99' }) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      err.code = err.response.status
      if (err.response.status == 501) {
        return location.href = '/sitedown'
      }
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(err.request);
    } else {
      // Something happened in setting up the request and triggered an err
      console.log('Unexpected Error', err.message);
    }
    const page = state.pageProperties;
    const errors = page.errors['de'] || []
    let defaultMsg = errors['default']
    console.error("Error:", err)
    if (err.cause) {
      if (errors[err.cause]) {
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: errors[err.cause] })
      } else {
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: defaultMsg })
      }
    } else if (err.customMsg) {
      let message = err.customMsg
      return commit('setModalOptions', { type: 'error', isActive: true, message: message })
    } else {
      if (err.code == '404') {
        let msg = defaultMsg
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '503') {
        let msg = errors['network']
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '502') {
        let msg = errors['network']
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.message == 'Network Error') {
        let msg = errors['network']
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else {
        let msg = defaultMsg
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      }
    }
  },
  refreshTempId: ({ state, dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/tempId`, { campaignId: getters.campaignId, token: state.token, iv: state.iv })
        .then(resp => {
          if (resp.data.response) {
            if (!location.pathname.includes("/iban-reupload")) resolve(resp.data.customerToken)
          } else {
            dispatch('handleError', { cause: 'customerToken', code: 97 })
            reject(resp.data.message)
          }
        })
        .catch(err => {
          dispatch('handleError', { cause: 'customerToken', code: 98 })
          reject(err)
        })
    })
  },
  signInWithOkta: ({ getters, state }, data) => {
    return new Promise((resolve, reject) => {
      const payload = {
        campaignId: getters.campaignId || null,
        customerToken: getters.customerToken || null,
        receiptToken: getters.receiptToken || null,
        accessToken: data?.accessToken ?? data?.backupAccessToken,
        token: getters.jwtToken || null
      }
      axios.post(`${APIUrl}/sign-in`, payload).then(res => {
        return resolve(res.data);
      }).catch((err) => {
        console.log(err)
      })
    })
  },
  checkEligibility: async ({ state, dispatch, commit }) => {
    let data = { campaignKey: state.campaignKey };
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("utm_source")) data["utmSource"] = urlParams.get("utm_source");
    else if (urlParams.has("utmSource")) data["utmSource"] = urlParams.get("utmSource");

    if (urlParams.has("utm_medium")) data["utmMedium"] = urlParams.get("utm_medium");
    else if (urlParams.has("utmMedium")) data["utmMedium"] = urlParams.get("utmMedium");

    if (urlParams.has("utm_term")) data["utmTerm"] = urlParams.get("utm_term");
    else if (urlParams.has("utmTerm")) data["utmTerm"] = urlParams.get("utmTerm");

    if (urlParams.has("utm_campaign")) data["utmCampaign"] = urlParams.get("utm_campaign");
    else if (urlParams.has("utmCampaign")) data["utmCampaign"] = urlParams.get("utmCampaign");

    // if (urlParams.has("isTest")) data["isTest"] = 1
    // data["isTest"] = 1
    // else data["isTest"] = false;

    try {
      const resp = await axios.post('https://service-manager.jsnp.io/api/checkEligable', data)
      if (resp.data.response) {
        const customerData = {
          field: 'customerToken',
          value: resp.data.customerToken
        }
        if (!location.pathname.includes("/iban-reupload")) commit('setField', customerData)
        const jwtData = {
          field: 'jwtToken',
          value: resp.data.token
        }
        commit('setField', jwtData)
        return true
      }
    } catch(err) {
      if (err.response?.data?.message === 'Campaign is not active yet or has expired') dispatch('handleError', { cause: 'notActive', isInfo: false })
      else if (err.response?.data?.message === 'Bad Request: campaignKey is required') dispatch('handleError', { cause: 'badRequest', isInfo: false })
      else if (err.response?.data?.message === 'Customer has an active session already. Please retry in 15 minutes') dispatch('handleError', { cause: 'alreadyActive', isInfo: false })
      else if (err.response?.data?.message?.includes('Campaign limit reached')) dispatch('handleError', { cause: 'dailyParticipationLimit', isInfo: true })
      else if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', isInfo: false })
      else dispatch('handleError', err)
      return false
    }
  },
  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  sendFormData: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }

      axios.post(APIUrl + data.endpoint, { ...data.formData })
        .then(res => {
          if (res.data.response) {
            if (res.data.referenceNumber) commit('setReferenceNumber', res.data.referenceNumber)
            return resolve(res.data)
          } else {
            if (res.data.invalid_fields) {
              //janrain error
              if (res.data.invalid_fields[0] == 'Email address is already in use.') {
                dispatch('handleError', { cause: 'emailUsed' })
              } else if (res.data.invalid_fields[0] == 'Incorrect username or password. Please try again.') {
                dispatch('handleError', { cause: 'incorrect' })
              } else if (res.data.invalid_fields[0] == 'No account with that email address exists.') {
                dispatch('handleError', { cause: 'noEmail' })
              } else if (res.data.message && res.data.message == 'authorization_code is not valid') {
                dispatch('handleError', { cause: 'invalidCode' })
              } else {
                dispatch('handleError', { customMsg: res.data.invalid_fields })
              }
            } else if (res.data.message) {
              let customMsg = res.data.message
              let code = 1
              if(res.data.code){
                code = res.data.code
              } else if (res.data.error){
                code = res.data.error
              }
              if (res.data.message.includes('Missing required field')) {
                if (res.data.message.includes('email')) {
                  code = '99'
                } else if (res.data.message.includes('password')) {
                  code = '98'
                } else if (res.data.message.includes('customerToken')) {
                  code = '97'
                } else if (res.data.message.includes('receiptToken')) {
                  code = '96'
                }
              }
              dispatch('handleError', { customMsg, code })
            } else {
              dispatch('handleError', { code: res.data })
            }
            return reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadProductPhotos({ getters, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('customerToken', data.customerToken)
      formData.append('campaignId', getters.campaignId)
      formData.append('receiptToken', data.receiptToken)
      formData.append('token', state.jwtToken)
      formData.append('productPhoto', data.image, 'productPhoto.png')

      let API = APIUrl
      if (formData.get('customerToken') == 1) {
        formData.append('token', data.token)
        API = APIUrlv3
      }

      axios.post(API + data.endpoint, formData)
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  generateCode({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      data.formData.token = state.jwtToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'generateCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  verifyCode({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      data.formData.token = state.jwtToken
      data.formData.privateToken = state.privateToken

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'wrongCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  sendProducts({ dispatch, state, getters }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.receiptToken = state.receiptToken
      data.formData.campaignId = getters.campaignId
      data.formData.token = state.jwtToken

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', { customMsg: res.data.message })
            reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadReceipt({ state, getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)
      }

      formData.append('userToken', data.customerToken)
      formData.append('receipt', data.image, 'receipt.png')
      // formData.append('subCampaignId', getters.campaignId)
      formData.append('campaignId', data.campaignId)
      formData.append('token', state.jwtToken)

      if (APIUrl.includes('localhost')) {
        data.apiUrl = 'http://localhost:3001/api/createReceipt'
        formData.set('campaignId', getters.campaignId)
        formData.append('imageUrl', 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/0814c81a-0d8a-4f70-ae00-be7752de6846.png')
      }
      return axios.post(`${data.apiUrl}`, formData)
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            // If reference number is returned, set reference number
            if (resp.data.referenceNumber) {
              const data = {
                field: 'formData',
                value: { 'referenceNumber': resp.data.referenceNumber }
              }
              commit('setField', data)
            }

            commit('setReceiptToken', resp.data.receiptToken)
            resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', { cause: 'invalidReceipt' } )
          reject(err)
        })
    })
  },

  searchProductByBarcode({ state, commit }, { barcode, maxProductCount, endpoint }) {
    // Check if the user has already added the product
    const idx = state.scannedProducts.findIndex(x => x.ean === barcode);
    if (idx > -1) {
      commit('setModalOptions', { type: 'error', isActive: true, message: i18n.t('You have already added this product.') });
      commit("setSearchStatus", null);
      return false;
    }
    // Check if the user has already added the maximum number of products
    if (maxProductCount <= state.scannedProducts.length) {
      commit('setModalOptions', { type: 'error', isActive: true, message: i18n.t('You have already added the maximum number of products.') });
      commit("setSearchStatus", null);
      return false;
    }
    
    if (/^[0-9]+$/.test(barcode)) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APIUrl + endpoint}`, {
            campaignId: state.campaignId,
            barcode: barcode,
            token: state.jwtToken,
          })
          .then(res => {
            let status = res.data.result.length > 0 ? 'success' : 'not_found';

            // Check if this type is already added
            const newProductCategory = res.data.result[0]?.category;
            const idx = state.scannedProducts.findIndex(x => x.category === newProductCategory);
            if (idx > -1) {
              commit('setModalOptions', { type: 'error', isActive: true, message: i18n.t('You have already added a product from this category.') });
              commit("setSearchStatus", null);
              return false;
            }
            commit("setSearchStatus", status);

            if (status === 'not_found') {
              // The barcode is not P&G
              reject(res.data);
            } else {
              commit('setErrorActivate', false)
              commit("setProducts", res.data.result);
            }
            resolve(res.data);
          }).catch(err => {
            reject(err)
          })
      });
    } else {
      // The barcode is invalid
      commit("setSearchStatus", 'not_found');
      commit('setScannerError', i18n.t('The barcode number is invalid. Try it again.'));
      commit('setErrorActivate', true)
    }
  },
  isUpdateIban: ({ state, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios.get(`${APIUrlv3}/ibanUpdateCheck/${state.customerToken}/${state.campaignId}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  check72Hours({ getters, state }) {
    const formData = {}
    formData.receiptToken = state.receiptToken
    formData.campaignId = getters.campaignId
    formData.token = state.jwtToken
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrlv3}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendSurveyData({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/survey`, data.formData)
        .then(resp => {
          if (resp.data.response) {
            resolve(resp.data)
          } else {
            dispatch('handleError', { cause: 'survey' })
            reject(resp.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  sendRequest ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      let API = APIUrl
      if (data.apiv3) API = APIUrlv3
      axios.post(API + data.endpoint, data.formData)
        .then(resp => {
          if (resp.data.response)
            resolve(resp.data)
          else
            reject(resp.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  getParticipationCount ({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      const formData = {
        campaignKey: state.campaignKey,
      }
      axios.post(`https://service-manager.jsnp.io/api/getRemaining`, formData)
        .then(resp => {
          state.remainingCount = resp.data.remaining
          resolve(resp.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver' })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  checkInvitedUser  ({ getters }, data){
    const formData = {};
    formData.token = data
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrlv3}/checkInvitedUser`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          location.href='/ariel-old/campaign-is-over'
        })
    })
  },
}
