import paragraph from "@/components/renderers/paragraph.vue"
import squareImage from "@/components/renderers/square-image.vue"
import keyVisual from "@/components/renderers/key-visual.vue"
import navbar from "@/components/renderers/navbar.vue"
import anchor from "@/components/renderers/anchor.vue"
import receiptProcessor from "@/components/renderers/receipt-processor.vue"
import productUpload from "@/components/renderers/product-upload.vue"
import formComponent from "@/components/renderers/form-component.vue"
import textInput from "@/components/renderers/text-input.vue"
import stepCard from "@/components/renderers/step-card.vue"
import stepsHorizontal from "@/components/renderers/steps-horizontal.vue"
import selectInput from "@/components/renderers/select-input.vue"
import radioInput from "@/components/renderers/radio-input.vue"
import dateInput from "@/components/renderers/date-input.vue"
import buttonComponent from "@/components/renderers/button-component.vue"
import productSelection from "@/components/renderers/ProductSelection/index.vue"
import emptyBucket from "@/components/renderers/empty-bucket.vue"
import receiptInformation from "@/components/renderers/receipt-information.vue"
import receiptInformationOld from "@/components/renderers/receipt-information-old.vue"
import receiptExamples from "@/components/renderers/receipt-examples.vue"
import smsVerification from "@/components/renderers/SmsVerification/sms-verification.vue"
import smsReceiving from "@/components/renderers/SmsVerification/sms-receiving.vue"
import checkbox from "@/components/renderers/checkbox.vue"
import gtinComponent from "@/components/renderers/gtin-component.vue"
import stepCounter from "@/components/renderers/step-counter.vue"
import footerComponent from "@/components/renderers/footer-component.vue"
import question from "@/components/renderers/question.vue"
import reclaimBucket from "@/components/renderers/reclaim-bucket.vue"
import dataLayerEvent from "@/components/renderers/datalayer-event.vue"
import refreshToken from "@/components/renderers/refresh-token.vue"
import queryStringToState from "@/components/renderers/query-string-to-state.vue"
import barcodeScanner from "@/components/renderers/BarcodeScanner/index.vue"
import ibanReupload from "@/components/renderers/iban-reupload.vue"
import checkReceiptToken from "@/components/renderers/checkReceiptToken.vue"
import participation from "@/components/renderers/participation.vue"
import doi from "@/components/renderers/doi.vue"
import progressBar from "@/components/renderers/progress-bar.vue"
import clipboard from "@/components/renderers/clipboard.vue"
import survey from "@/components/renderers/survey.vue"
import faq from "@/components/renderers/faq.vue"
import productList from "@/components/renderers/ProductList.vue"
import productInformation from "@/components/renderers/product-information.vue"
import docsEmbed from "@/components/renderers/docs-embed.vue"
import campaignDisclaimer from "@/components/renderers/campaign-disclaimer.vue"
import pdfViewer from "@/components/renderers/pdf-viewer"
import termsComponent from "@/components/renderers/terms-component"
import signInOkta from "@/components/renderers/sign-in-okta.vue"
import navbarNew from "@/components/renderers/navbar-new.vue"
import campaignGrid from "@/components/renderers/campaign-grid.vue"


export default [
  barcodeScanner,
  refreshToken,
  dataLayerEvent,
  reclaimBucket,
  ibanReupload,
  question,
  stepCounter,
  receiptInformation,
  receiptInformationOld,
  receiptExamples,
  smsVerification,
  smsReceiving,
  paragraph,
  squareImage,
  emptyBucket,
  keyVisual,
  navbar,
  anchor,
  receiptProcessor,
  productUpload,
  formComponent,
  textInput,
  stepCard,
  selectInput,
  radioInput,
  dateInput,
  buttonComponent,
  productSelection,
  checkbox,
  gtinComponent,
  footerComponent,
  queryStringToState,
  checkReceiptToken,
  participation,
  doi,
  progressBar,
  clipboard,
  stepsHorizontal,
  survey,
  faq,
  productList,
  productInformation,
  docsEmbed,
  campaignDisclaimer,
  pdfViewer,
  termsComponent,
  signInOkta,
  navbarNew,
  campaignGrid
];
