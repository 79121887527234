var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campaignWrapper"},[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"separator"},[_vm._v("NEUE AKTIONEN")]),_c('div',{staticClass:"row justify-content-center",style:(_vm.activeCampaigns.length ? 'margin-top: 50px' : '')},_vm._l((_vm.activeCampaigns),function(campaign){return _c('div',{key:campaign.name,staticClass:"col-lg-6 col-12 campaign"},[_c('p',{staticClass:"title",domProps:{"innerHTML":_vm._s(campaign.name)}}),_c('div',{staticClass:"shadow"},[_c('img',{staticClass:"visual",class:campaign.className != null ? campaign.className : '',attrs:{"src":campaign.visual,"alt":campaign.name}})]),(campaign.dateText1)?_c('div',{staticClass:"campaignDuration"},[_vm._v(" "+_vm._s(campaign.dateText1)+" | "+_vm._s(campaign.dateText2)+" ")]):_vm._e(),_c('div',{staticClass:"row contentWrapper"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('buttonComponent',{attrs:{"grid":{
                columns: '12',
                alignItems: 'center',
                justifyContent: 'center',
              },"addClass":"multi-campaign","name":"TEILNEHMEN","text":"TEILNEHMEN"},nativeOn:{"click":function($event){return _vm.goToCampaign(campaign)}}})],1)])])}),0),(_vm.endedCampaigns.length)?_c('h3',{staticClass:"separator"},[_vm._v(" BEENDETE AKTIONEN ")]):_vm._e(),_c('div',{staticClass:"row",staticStyle:{"margin-top":"50px"}},_vm._l((_vm.endedCampaigns),function(campaign,idx){return _c('div',{key:campaign.name,staticClass:"col-lg-4 col-12 campaign",class:{ active: _vm.hoveringIndex == idx },on:{"mouseover":function($event){_vm.hoveringIndex = idx},"mouseleave":function($event){_vm.hoveringIndex = -1}}},[_c('p',{staticClass:"title",domProps:{"innerHTML":_vm._s(campaign.name)}}),_c('div',{staticClass:"shadow"},[_c('img',{staticClass:"visual",style:('mix-blend-mode: luminosity;'),attrs:{"src":campaign.visual,"alt":campaign.name}})]),_c('div',{staticClass:"row contentWrapper"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('buttonComponent',{attrs:{"grid":{
                columns: '12',
                alignItems: 'center',
                justifyContent: 'center',
              },"addClass":"btn multi-campaign","name":"EINZELHEITEN","text":"TEILNAHMEBEDINGUNGEN"},nativeOn:{"click":function($event){return _vm.goToCampaign(campaign)}}})],1)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }